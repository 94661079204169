<script lang="ts" setup>
import Help from '../display/Help.vue'

const props = defineProps<{
  tmsName: string
  documentationLink: string | undefined
  modelValue: // config value
  { [key: string]: any }
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: { [key: string]: any }): void
}>()

const config = useVModel(props, 'modelValue', emit)
</script>

<template>
  <el-form-item label="Auto enter appointments:">
    <el-checkbox v-model="config.auto_enter_appointments_in_tms" />
    <Help title="Auto Enter Appointments" class="ml-2"
      >When you book or reschedule on Dray Dog, we automatically enter the
      appointment time into {{ tmsName }}. However, you can turn this on and
      off.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
  <el-form-item label="Auto enter empty appointments:">
    <el-checkbox v-model="config.auto_enter_empty_appointments_in_tms" />
    <Help title="Auto Enter Appointments" class="ml-2"
      >When you book an empty appointment on Dray Dog, we automatically enter
      the appointment time into {{ tmsName }}. However, you can turn this on and
      off.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
  <el-form-item label="Don't enter dual empty-in appointments:">
    <el-checkbox v-model="config.dont_enter_dual_empty_appointments_in_tms" />
    <Help title="Don't Enter Dual Appointments" class="ml-2"
      >When auto enter empty appointments is on, this setting will prevent dual
      empty-in appointments from being entered into {{ tmsName }}. Note: for
      duals to be entered, auto enter empty appointments must be on with this
      option not checked.
    </Help>
  </el-form-item>
  <el-form-item label="Send Pickup Number:">
    <el-checkbox v-model="config.send_pickup_number" />
    <Help title="Send Pickup Number" class="ml-2"
      >This option allows you to send the pickup number, the number required to
      pick up the container, instead of the standard appointment number.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
</template>
