<script lang="ts" setup>
import {
  ElAlert,
  ElButton,
  ElDialog,
  ElForm,
  ElFormItem,
  ElNotification,
} from 'element-plus'
import type { FormInstance, FormItemProp } from 'element-plus/lib/components'
import { startCase } from 'lodash'
import { useShippingLine } from '~/compositions/useShippingLines'
import {
  TERMINALS_NOT_REQUIRING_EMPTY_APPOINTMENTS_FOR_DUALS,
  getContainerTypeDescription,
  getTerminalLabel,
} from '~/constants'
import type { Appointment } from '~/models/appointments'
import { BasicContainer } from '~/models/containers'
import type { ReturnRule } from '~/models/returnRules'
import type {
  ContainerType,
  GETShippingLine,
  ShippingLine,
} from '~/services/apiClient'
import {
  AppointmentBookingApi,
  ContainerWatchState,
  TerminalName,
} from '~/services/apiClient'
import { loadContainersBasic } from '~/services/apiHelpers'
import { usedBasicContainersStore } from '~/stores/basicContainers'
import { useContainerTypesStore } from '~/stores/containerTypes'
import { useReturnRulesLatest } from '~/stores/returnRulesLatest'
import { useUserStore } from '~/stores/user'
import { CONTAINER_RE } from '~/utils'
import EmptyContainerSelector from '~/components/inputs/EmptyContainerSelector.vue'
import ReturnRuleInfoModal from '~/components/emptyReturnRules/ReturnRuleInfoModal.vue'
import ShippingLineSelector from '~/components/inputs/ShippingLineSelector.vue'
import ContainerTypeSelector from '~/components/inputs/ContainerTypeSelector.vue'
import Help from '~/components/display/Help.vue'
import { checkForDualLengthMisMatchViolation } from '~/utils/ContainerUtils'

interface ContainerRequirements {
  terminal: TerminalName | null
  container_number: string
  container_type: ContainerType | null
  customer_name: string | null
}
interface HasLineAndType {
  shipping_line: ShippingLine
  container_type: ContainerType
}
const props = withDefaults(
  defineProps<{
    container: ContainerRequirements
    appointment: Appointment
    showReturnRules: boolean
    // If line+type are passed, we "fix" the dialog to only work with these values
    shippingLine?: ShippingLine
    containerType?: ContainerType
    prefillContainerNumber?: string | null
    appendToBody?: boolean
  }>(),
  { appendToBody: true }
)

const emit = defineEmits<{ (e: 'close'): void }>()
// Stores
const containerTypeStore = useContainerTypesStore()
const basicContainersStore = usedBasicContainersStore()
const latestReturnRulesStore = useReturnRulesLatest()
const userStore = useUserStore()
const { getShippingLine } = useShippingLine()

const dialogOpen = ref(true)
const receivingRulesDialogOpen = ref(false)
function closeDialog() {
  dialogOpen.value = false
  emit('close')
}
// Computed properties
const title = computed(() => {
  if (
    props.appointment.linked_appointments.length > 0 ||
    props.appointment.is_empty_in
  ) {
    return 'Swap Empty'
  }
  return 'Add Empty'
})

const receivingOptions = computed((): ReturnRule[] => {
  if (!props.showReturnRules) {
    return []
  }
  if (latestReturnRulesStore.loading) {
    return []
  }
  // Get return options
  const returnRules = latestReturnRulesStore.lookupRulesForTerminal(
    props.appointment.terminal,
    props.appointment.shiftDate,
    props.appointment.shift
  )
  return returnRules.filter((rule) => rule.can_dual)
})
const receivingOptionsToShowContainersFor = computed((): HasLineAndType[] => {
  if (props.shippingLine && props.containerType) {
    return [
      {
        shipping_line: props.shippingLine,
        container_type: props.containerType,
      },
    ]
  } else {
    return receivingOptions.value
  }
})
const receivingShippingLines = computed((): GETShippingLine[] => {
  const shippingLines = new Set<ShippingLine>()
  for (const rule of receivingOptions.value) {
    if (rule.can_dual) {
      shippingLines.add(rule.shipping_line)
    }
  }
  // Lookup shipping line info
  const shippingLineInfos: GETShippingLine[] = []
  for (const line of shippingLines) {
    const shippingLine = getShippingLine(line)
    if (shippingLine) {
      shippingLineInfos.push(shippingLine)
    }
  }
  return shippingLineInfos
})
const terminal = toRef(props.appointment, 'terminal')
const terminalLabel = computed(() => getTerminalLabel(terminal.value))
const selectedContainer = ref<BasicContainer | null>(null)
const EMPTY_CONTAINER_FIELD_NAME = ref('empty_container_number')
const formRef = ref<FormInstance>()
const isOwnChassis = ref(false)
const form = reactive({
  empty_container_number: props.prefillContainerNumber || '',
  shipping_line: props.shippingLine as ShippingLine | undefined,
  container_type: props.containerType as ContainerType | undefined,
  chassis_number: null,
})
const selectedShippingLine = computed(() => {
  if (form.shipping_line) {
    return getShippingLine(form.shipping_line)
  }
  return null
})
const receivingRule = computed(() => {
  if (form.shipping_line && form.container_type) {
    return latestReturnRulesStore.lookupRule(
      form.shipping_line,
      form.container_type,
      terminal.value,
      props.appointment.shiftDate,
      props.appointment.shift
    )
  }
  return null
})
const formRules = reactive({
  empty_container_number: [
    {
      required: true,
      message: 'Required',
      trigger: 'change',
    },
    {
      trigger: 'change',
      pattern: CONTAINER_RE,
      message: 'Invalid container number',
    },
  ],
  shipping_line: [
    {
      required: true,
      message: 'Required',
      trigger: 'change',
    },
  ],
  container_type: [
    {
      required: true,
      message: 'Required',
      trigger: 'change',
    },
  ],
  chassis_number: [
    {
      required: false,
      message: 'Required',
      trigger: 'change',
    },
  ],
})
watch([terminal, isOwnChassis], () => {
  if (terminal.value === TerminalName.Everport && isOwnChassis.value === true) {
    formRules.chassis_number[0].required = true
  } else {
    formRules.chassis_number[0].required = false
  }
})
const loadingEmptyData = ref(false)

const dualViolationMessage = computed(() => {
  if (
    !form.container_type ||
    !props.container.terminal ||
    !props.container.container_type
  ) {
    return null
  }
  const dualViolation = checkForDualLengthMisMatchViolation(
    props.container.terminal,
    form.container_type,
    props.container.container_type
  )
  return dualViolation !== undefined ? dualViolation : null
})

const dualViolationAlertType = computed(() => {
  return ['apm', 'lbct'].includes(props.appointment.terminal)
    ? 'error'
    : 'warning'
})

async function validateEmptyContainer() {
  // Try to use value from our list of empty options first
  let container = basicContainersStore.containersLookup.get(
    form.empty_container_number
  )
  if (!container) {
    loadingEmptyData.value = true
    // Fallback to any container in our system
    try {
      const resp = await loadContainersBasic({
        containers: form.empty_container_number,
      })
      if (resp.data.length > 0) {
        container = new BasicContainer(resp.data[0], userStore.demo_mode)
      }
    } finally {
      loadingEmptyData.value = false
    }
  }
  if (container) {
    selectedContainer.value = container
    if (container.container_type) {
      form.container_type = container.container_type
    }
    if (container.shipping_line) {
      form.shipping_line = container.shipping_line
    }
  } else {
    selectedContainer.value = null
  }
}

async function validateField(prop: FormItemProp, isValid: boolean) {
  if (prop === EMPTY_CONTAINER_FIELD_NAME.value && isValid) {
    await validateEmptyContainer()
  }
}

function addEmpty() {
  const api = new AppointmentBookingApi()
  const existingLinkedAppointments = props.appointment.linked_appointments || []
  const currentTerminal = props.container.terminal
  if (!currentTerminal) {
    return
  }

  if (existingLinkedAppointments.length > 0 && !props.appointment.is_empty_in) {
    // swapping from the load out using the linked appointment info
    api
      .editAppointmentSwapEmptyAppointmentsBookingSwapEmptyPost({
        terminal_reference: existingLinkedAppointments[0].terminal_reference,
        existing_container_number:
          existingLinkedAppointments[0].container_number,
        empty_container_number: form.empty_container_number,
        shipping_line: form.shipping_line!,
        container_type: form.container_type!,
        chassis_number: form.chassis_number || undefined,
      })
      .then(() => {
        ElNotification.success({ title: 'Swap empty started' })
        closeDialog()
      })
  } else {
    // pure add empty case
    api
      .editAppointmentAddEmptyAppointmentsBookingAddEmptyPost({
        import_appointment_id: props.appointment.id,
        empty_container_number: form.empty_container_number,
        shipping_line: form.shipping_line!,
        container_type: form.container_type!,
        chassis_number: form.chassis_number || undefined,
      })
      .then(() => {
        // TODO: Improve title
        ElNotification.success({ title: 'Add empty started' })
        closeDialog()
      })
  }
}

function getShippingLineName(line: ShippingLine): string {
  const lineInfo = getShippingLine(line)
  if (lineInfo) {
    return lineInfo.long_desc
  } else {
    return line
  }
}
function submitForm() {
  formRef.value!.validate().then((isValid) => {
    if (isValid) {
      addEmpty()
    }
  })
}

onMounted(() => {
  containerTypeStore.loadContainerTypes()
  if (form.empty_container_number) {
    // Trigger validation right away if we have a pre-filled container number
    validateEmptyContainer()
  }
})
</script>

<template>
  <ElDialog
    :model-value="dialogOpen"
    :append-to-body="props.appendToBody"
    :title="title"
    width="34%"
    center
    destroy-on-close
    @close="emit('close')"
  >
    <div
      v-if="
        terminal &&
        TERMINALS_NOT_REQUIRING_EMPTY_APPOINTMENTS_FOR_DUALS.has(terminal)
      "
      class="mb-2"
    >
      <ElAlert type="success" :closable="false">
        <i-mdi:information class="align-middle" />
        Empty in appointment not required for duals at
        {{ terminalLabel }}
      </ElAlert>
    </div>
    <ElForm
      v-else
      ref="formRef"
      :model="form"
      :rules="formRules"
      label-width="auto"
      status-icon
      size="small"
      class="add-empty-dialog-form"
      @validate="validateField"
    >
      <ElFormItem label="Load out container #:">
        {{ props.container.container_number }}
      </ElFormItem>
      <ElFormItem label="Load out customer:">
        {{ props.container.customer_name }}
      </ElFormItem>
      <ElFormItem label="Terminal:">
        {{ terminalLabel }}
      </ElFormItem>
      <ElFormItem label="Shift:">
        {{ props.appointment.scheduledShift }}
      </ElFormItem>
      <ElFormItem v-if="props.showReturnRules" label="Receiving duals for:">
        <div>
          <ColoredBadge
            v-for="shippingLineInfo in receivingShippingLines"
            :key="shippingLineInfo.shipping_line"
            effect="dark"
            size="default"
            class="mr-2"
          >
            {{ shippingLineInfo.long_desc }}
          </ColoredBadge>
        </div>
      </ElFormItem>
      <ElFormItem label="Empty container #:" :prop="EMPTY_CONTAINER_FIELD_NAME">
        <EmptyContainerSelector
          v-model="form.empty_container_number"
          :limit-to-line-and-types="receivingOptionsToShowContainersFor"
          class="empty-selector"
        />
        <Help title="Selecting an empty" class="ml-1">
          <p>
            Select from a list of recently out-gated import containers without a
            return appointment and where the container type and shipping line
            are being received for this terminal shift.
          </p>
          <p>You are can also enter any container number you like manually.</p>
          <p>You can also type to filter in several ways:</p>
          <ul>
            <li>Customer name. Example: Walmart</li>
            <li>Shipping line. Example: ONE</li>
            <li>Container type. Example: 40SD</li>
            <li>Line and type. Example: ONE/40SD</li>
            <li>Container number prefix. Example: SEGU</li>
          </ul>
        </Help>
        <template v-if="selectedContainer">
          <div v-if="selectedContainer.import_out_gated_time">
            Container was out-gated
            {{ selectedContainer.import_out_gated_time.toRelative() }}
            <template v-if="selectedContainer.last_related_terminal">
              from
              {{ getTerminalLabel(selectedContainer.last_related_terminal) }}
            </template>
          </div>
          <div
            v-if="
              selectedContainer.cycle_state !==
              ContainerWatchState.ImportPickedUp
            "
            class="text-red-600"
          >
            Container not an out-gated import. Status:
            <i> {{ startCase(selectedContainer.cycle_state) }}</i>
          </div>
        </template>
        <div
          v-if="
            !selectedContainer &&
            form.empty_container_number &&
            !loadingEmptyData
          "
          class="text-orange-400"
        >
          {{ form.empty_container_number }} not found in Dray Dog.
          <span class="text-black"
            >This is fine, just double check you have a valid empty</span
          >
        </div>
      </ElFormItem>
      <!-- TODO: make these dropdowns -->
      <ElFormItem label="Shipping line:" prop="shipping_line">
        <span v-if="props.shippingLine">
          {{ getShippingLineName(props.shippingLine) }}
        </span>
        <ShippingLineSelector
          v-else
          v-model="form.shipping_line"
          v-loading="loadingEmptyData"
          class="line-type-selectors"
        />
      </ElFormItem>
      <ElFormItem label="Container type:" prop="container_type">
        <span v-if="props.containerType">
          {{ getContainerTypeDescription(props.containerType) }}
        </span>
        <ContainerTypeSelector
          v-else
          v-model="form.container_type"
          v-loading="loadingEmptyData"
          class="line-type-selectors"
        />
      </ElFormItem>
      <ElFormItem
        v-if="terminal === TerminalName.Everport"
        label="Chassis Type:"
      >
        <el-switch
          v-model="isOwnChassis"
          :active-value="true"
          :inactive-value="false"
          active-text="Pool Chassis"
          inactive-text="Own Chassis"
          active-color="#13ce66"
          inactive-color="#ff4949"
        />
      </ElFormItem>
      <ElFormItem
        v-if="terminal === TerminalName.Everport"
        label="Chassis Number:"
        prop="chassis_number"
      >
        <el-input
          v-if="isOwnChassis"
          v-model="form.chassis_number"
          size="large"
          placeholder="FLTZ406909"
          class="max-w-196px"
        />
        <el-input
          v-else
          size="large"
          placeholder="Not Required"
          class="max-w-196px"
          disabled
        />
      </ElFormItem>
      <template
        v-if="
          selectedShippingLine && form.container_type && props.showReturnRules
        "
      >
        <ElAlert
          v-if="receivingRule"
          :type="receivingRule.can_dual ? 'success' : 'error'"
          effect="dark"
          show-icon
          :closable="false"
        >
          <template #title>
            <div class="flex justify-between">
              <div class="mr-2">
                {{ terminalLabel }} is
                {{ receivingRule.can_dual ? 'accepting' : 'not accepting' }}
                duals for
                {{ selectedShippingLine.long_desc }}
                {{ form.container_type }} empties for
                {{ props.appointment.scheduledShift }}
              </div>
              <ElButton size="default" @click="receivingRulesDialogOpen = true">
                View Rules
              </ElButton>
            </div>
          </template>
        </ElAlert>
        <ElAlert
          v-if="dualViolationMessage"
          :type="dualViolationAlertType"
          effect="dark"
          show-icon
          :closable="false"
        >
          <template #title>
            {{ dualViolationMessage }}
          </template>
        </ElAlert>
        <ElAlert
          v-else-if="props.showReturnRules"
          type="warning"
          effect="dark"
          show-icon
          :closable="false"
        >
          <template #title>
            We don't know what
            {{ terminalLabel }}'s return rules are for
            {{ selectedShippingLine.long_desc }}
            {{ form.container_type }}
            {{ props.appointment.scheduledShift }}
          </template>
          <template #default>
            It's likely that the terminal is not accepting at this time, but you
            should confirm yourself
          </template>
        </ElAlert>
      </template>

      <ElAlert
        v-if="props.appointment.linked_appointments.length > 0"
        type="warning"
        show-icon
        :closable="false"
        class="mb-2"
      >
        Swap Empty is a beta product. It may not work every time.
      </ElAlert>
    </ElForm>
    <template #footer>
      <div class="flex">
        <ElButton
          size="large"
          class="flex-1 fs-close-add-empty-dialog"
          @click="closeDialog"
        >
          Cancel
        </ElButton>
        <ElButton
          v-if="
            terminal &&
            TERMINALS_NOT_REQUIRING_EMPTY_APPOINTMENTS_FOR_DUALS.has(
              terminal
            ) === false
          "
          size="large"
          type="primary"
          class="flex-1 fs-add-empty"
          :disabled="!form.empty_container_number || loadingEmptyData"
          @click="submitForm"
        >
          <div v-if="props.appointment.linked_appointments.length > 0">
            Swap Empty
          </div>
          <div v-else>Add Empty</div>
        </ElButton>
      </div>
    </template>
  </ElDialog>

  <ReturnRuleInfoModal
    v-if="receivingRulesDialogOpen && receivingRule && props.showReturnRules"
    :model-value="receivingRulesDialogOpen"
    :return-rule="receivingRule"
    :append-to-body="props.appendToBody"
    @close="receivingRulesDialogOpen = false"
  />
</template>

<style lang="scss">
.add-empty-dialog-form .el-form-item__label-wrap {
  @apply items-center;
}
.add-empty-dialog-form .el-form-item__label {
  font-weight: bold;
}
.add-empty-dialog-form .el-form-item {
  margin-right: 15px;
}

.empty-selector {
  max-width: 80%;
}

.line-type-selectors {
  max-width: 80%;
}
</style>
