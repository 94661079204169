import { TerminalName } from '~/services/apiClient'

export interface ReadingSource {
  terminal: TerminalName
  source: string
  requiresLogin?: boolean
}

export enum TerminalReadingType {
  EmptyReturnRules = 'empty_return_rules',
  GateSchedule = 'gate_schedule',
}

export const EMPTY_RETURN_RULE_SOURCES: ReadingSource[] = [
  {
    terminal: TerminalName.Apm,
    source:
      'https://www.apmterminals.com/en/los-angeles/practical-information/empty-receivables-and-open-areas',
  },
  { terminal: TerminalName.Everport, source: 'https://www.etslink.com/' },
  {
    terminal: TerminalName.Fms,
    source: 'https://portal.fenixmarineservices.com/apptmt-app/home',
  },
  {
    terminal: TerminalName.Its,
    source: 'https://www.itslb.com/empty-receiving-updates/',
  },
  {
    terminal: TerminalName.Lbct,
    source: 'https://www.lbct.com/Operations/EmptyRestrictions',
  },
  {
    terminal: TerminalName.Pct,
    source: 'https://pct.tideworks.com/fc-PCT/home/default.do?method=page&id=0',
    requiresLogin: true,
  },
  {
    terminal: TerminalName.PierA,
    source:
      'https://piera.tideworks.com/fc-PA/home/default.do?method=page&id=0',
    requiresLogin: true,
  },
  {
    terminal: TerminalName.Trapac,
    source: 'https://losangeles.trapac.com/empty-returns/',
  },
  {
    terminal: TerminalName.Tti,
    source: 'https://www.totalterminals.com/gate-schedule',
  },
  {
    terminal: TerminalName.Wbct,
    source: 'https://voyagertrack.portsamerica.com/logon?siteId=WBCT_LA',
  },
  {
    terminal: TerminalName.Yti,
    source: 'https://yti.com/terminal-schedules/empty-receiving-schedule/',
  },
]

export const GATE_SCHEDULE_SOURCES: ReadingSource[] = [
  {
    terminal: TerminalName.Apm,
    source:
      'https://www.apmterminals.com/en/los-angeles/practical-information/hours-of-operations',
  },
  {
    terminal: TerminalName.Everport,
    source: 'https://www.etslink.com/',
  },
  {
    terminal: TerminalName.Fms,
    source: 'https://portal.fenixmarineservices.com/apptmt-app/home',
  },
  {
    terminal: TerminalName.Its,
    source: 'https://www.itslb.com/gate-hours-of-operation/',
  },
  {
    terminal: TerminalName.Lbct,
    source: 'https://www.lbct.com/Operations/TruckGateHours',
  },
  {
    terminal: TerminalName.Pct,
    source: 'https://pct.tideworks.com/fc-PCT/home/default.do',
    requiresLogin: true,
  },
  {
    terminal: TerminalName.PierA,
    source: 'https://piera.tideworks.com/fc-PA/home/default.do',
    requiresLogin: true,
  },
  {
    terminal: TerminalName.Trapac,
    source: 'https://losangeles.trapac.com/gate-hours/',
  },
  {
    terminal: TerminalName.Tti,
    source: 'https://www.totalterminals.com/gate-schedule',
  },
  {
    terminal: TerminalName.Wbct,
    source: 'https://voyagertrack.portsamerica.com/logon?siteId=WBCT_LA',
  },
  {
    terminal: TerminalName.Yti,
    source: 'https://yti.com/terminal-schedules/gate-schedule/',
  },
]
