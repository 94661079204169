<script lang="ts" setup>
import { RecycleScroller } from 'vue-virtual-scroller'
import type { DateTime } from 'luxon'
import type { AppointmentTransactionGroup } from './types'
import { CapacityBucketAndCount, ShiftGroup } from './types'
import {
  groupContainersIntoTransactions,
  shiftGroupsToAppointedContainers,
} from './helpers'
import AppointedContainerRow from './AppointedContainerRow.vue'
import ShiftGroupHeader from './ShiftGroupHeader.vue'
import HourGroupHeader from './HourGroupHeader.vue'
import type { Container } from '~/models/containers'
import { useCapacitiesStore } from '~/stores/capacities'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { shiftNameFromTime } from '~/logic/shifts'
const props = defineProps({
  containers: {
    type: Array as PropType<Container[]>,
    required: true,
  },
})
const capacitiesStore = useCapacitiesStore()
onMounted(capacitiesStore.loadBucketsIfNotLoaded)

const transactions = computed(() => {
  return groupContainersIntoTransactions(props.containers)
})

const shiftGroups = computed(() => {
  const containersByShiftAndHour = new Map() as Map<string, ShiftGroup>
  const bucketsLookup = new Map() as Map<string, CapacityBucketAndCount>
  transactions.value.forEach((transaction) => {
    const { empty_in, load_out } = transaction
    const bucketsThatApplyToTransaction = new Map<
      string,
      CapacityBucketAndCount
    >()
    let shiftStart: DateTime | undefined
    if (load_out) {
      if (load_out.scheduledShiftStart) {
        const capacityBuckets = capacitiesStore.getBucketsForTime(
          load_out.latest_appointment!.window_start,
          load_out.customer_id ?? null,
          load_out.tags
        )
        for (const capacityBucket of capacityBuckets) {
          const bucketWithCount =
            bucketsLookup.get(capacityBucket.key) ||
            new CapacityBucketAndCount(capacityBucket)
          if (load_out) {
            // Capacity is only for load outs
            bucketWithCount.numScheduledAppointments += 1
          }
          bucketsLookup.set(capacityBucket.key, bucketWithCount)
          bucketsThatApplyToTransaction.set(capacityBucket.key, bucketWithCount)
        }
        shiftStart = load_out.scheduledShiftStart
      } else {
        console.warn('Container without shift', transaction)
      }
    } else if (empty_in) {
      // Single empty in case
      if (empty_in.scheduledShiftStart) {
        shiftStart = empty_in.scheduledShiftStart
      } else {
        console.warn('Container without shift', transaction)
      }
    }
    if (shiftStart) {
      const shiftName = shiftNameFromTime(shiftStart)
      const shiftGroup =
        containersByShiftAndHour.get(shiftName) || new ShiftGroup(shiftStart)
      containersByShiftAndHour.set(shiftName, shiftGroup)
      shiftGroup.addTransaction(transaction, [
        ...bucketsThatApplyToTransaction.values(),
      ])
    }
  })
  const rows = shiftGroupsToAppointedContainers([
    ...containersByShiftAndHour.values(),
  ])
  return rows
})
const visibleRows = new Map() as Map<string, AppointmentTransactionGroup>
const firstVisibleRow = ref(null as AppointmentTransactionGroup | null)
function setFirstVisibleRow() {
  let lowestIndex = Number.MAX_SAFE_INTEGER
  let bestRow = null as AppointmentTransactionGroup | null
  for (const row of visibleRows.values()) {
    if (row.index < lowestIndex) {
      lowestIndex = row.index
      bestRow = row
    }
  }
  firstVisibleRow.value = bestRow
}
function onRowHidden(row: AppointmentTransactionGroup) {
  visibleRows.delete(row.key)
  setFirstVisibleRow()
}
function onRowVisible(row: AppointmentTransactionGroup) {
  visibleRows.set(row.key, row)
  setFirstVisibleRow()
}
</script>

<template>
  <!-- Sticky header -->
  <div class="absolute top-0 left-0 px-2 w-full" style="z-index: 20">
    <ShiftGroupHeader
      v-if="firstVisibleRow"
      :shift-group="firstVisibleRow.shiftGroup"
    />
    <HourGroupHeader
      v-if="firstVisibleRow"
      :hour-group="firstVisibleRow.hourGroup"
    />
  </div>
  <!-- Scroll area -->
  <RecycleScroller
    v-slot="{ item: row }"
    :items="shiftGroups"
    size-field="rowHeight"
    key-field="key"
    page-mode
    :buffer="600"
    class="h-full"
  >
    <AppointedContainerRow
      :row="row"
      @hidden="onRowHidden"
      @visible="onRowVisible"
    />
  </RecycleScroller>
</template>
