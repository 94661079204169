import { defineStore } from 'pinia'
import type { DateTime } from 'luxon'
import { useUserStore } from './user'
import { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import type {
  GETAppointmentWithContainer,
  GETContainer,
  GETWatch,
  TransactionDirection,
} from '~/services/apiClient'
import { AppointmentStatus, AppointmentsApi } from '~/services/apiClient'
import { createRandomGETAppointmentsWithContainers } from '~/models/fixtureFactories'

interface LoadParams {
  after: DateTime
  before?: DateTime
  direction?: TransactionDirection
  loaded?: boolean
  statuses?: AppointmentStatus[]
  limitToRelevantAppointments: boolean
  containers?: string[]
}

export const useAppointmentWithContainerInfoStore = defineStore(
  'appointments-with-container-info',
  () => {
    const userStore = useUserStore()
    const loading = ref(false)
    const appointmentsByID = ref<Map<number, AppointmentWithContainerInfo>>(
      new Map()
    )
    const idsFromLastLoad = ref<number[]>([])
    const appointmentsByContainerNumber = ref<
      Map<string, Map<string, AppointmentWithContainerInfo>>
    >(new Map())
    const mostRecentLoadParams = ref<LoadParams | null>(null)

    const appointments = computed(() => {
      const appointments = Array.from(appointmentsByID.value.values())
      appointments.sort((a, b) => {
        return a.appointment.window_start
          .diff(b.appointment.window_start)
          .as('seconds')
      })
      return appointments
    })
    const appointmentsMatchingSearchParams = computed(() => {
      return appointments.value.filter((appt) =>
        appointmentInfoMatchesSearchParams(appt, mostRecentLoadParams.value)
      )
    })

    function load(params: LoadParams): Promise<void> {
      loading.value = true
      let promise: Promise<GETAppointmentWithContainer[]>
      if (userStore.testMode) {
        promise = loadMockData(params)
      } else {
        promise = loadLiveData(params)
      }
      return promise
        .then((resp) => {
          mostRecentLoadParams.value = params
          idsFromLastLoad.value = resp.map((appt) => appt.appointment.id)
          resp.forEach((rawApptInfo) => {
            const appointment = new AppointmentWithContainerInfo(
              rawApptInfo,
              userStore.demo_mode
            )
            addAppointmentToRefs(appointment)
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    function addAppointmentToRefs(
      appointmentInfo: AppointmentWithContainerInfo
    ) {
      // By ID
      appointmentsByID.value.set(
        appointmentInfo.appointment.id,
        appointmentInfo
      )
      // By Container Number
      let containerNumberMap = appointmentsByContainerNumber.value.get(
        appointmentInfo.container_number
      )
      if (!containerNumberMap) {
        containerNumberMap = new Map()
        appointmentsByContainerNumber.value.set(
          appointmentInfo.container_number,
          containerNumberMap
        )
      }
      containerNumberMap.set(
        appointmentInfo.appointment.terminal_reference,
        appointmentInfo
      )
    }
    function clearAllData() {
      appointmentsByID.value = new Map()
    }
    /**
     * For live-updating this store based on container updates that are sent to us over
     * websockets. Piggybacking on this existing feed so we don't have to setup a
     * secondary feed and potentially miss something.
     * NOTE: This will technically miss some updates for containers that have multiple,
     * active appointments.
     * @param container - The container that was updated
     */
    function updateFromContainer(container: GETContainer) {
      container.other_appointment_counts.forEach((apptCount) => {
        if (apptCount.status === AppointmentStatus.Scheduled) {
          // eslint-disable-next-line no-console
          console.log(
            `Received update for appointment with other ` +
              `scheduled appointments: ${container.container_number}`
          )
          // TODO: Load this appointment specifically?
        }
      })
      const appointmentInfo = containerToAppointmentWithContainerInfo(container)
      if (appointmentInfo) {
        // eslint-disable-next-line no-console
        console.log(
          `Received update for appointment with container info ${appointmentInfo.container_number}/${appointmentInfo.watch.shipping_line}/${appointmentInfo.watch.container_type}`
        )
        addAppointmentToRefs(appointmentInfo)
      }
    }
    function appointmentInfoMatchesSearchParams(
      apptInfo: AppointmentWithContainerInfo,
      params: LoadParams | null
    ): boolean {
      if (!params) {
        // If we haven't loaded anything, nothing matches
        return false
      }
      // If we had already loaded this appointment, it matches
      if (idsFromLastLoad.value.includes(apptInfo.appointment.id)) {
        return true
      }
      if (params.after && apptInfo.appointment.window_start < params.after) {
        return false
      }
      if (params.before && apptInfo.appointment.window_start >= params.before) {
        return false
      }
      if (
        params.direction &&
        apptInfo.appointment.direction !== params.direction
      ) {
        return false
      }
      if (
        params.loaded !== undefined &&
        apptInfo.appointment.loaded !== params.loaded
      ) {
        return false
      }
      if (
        params.statuses &&
        !params.statuses.includes(apptInfo.appointment.status)
      ) {
        return false
      }
      // TODO: limitToRelevantAppointments? Not sure how to do this yet.
      return true
    }

    function containerToAppointmentWithContainerInfo(
      container: GETContainer
    ): AppointmentWithContainerInfo | null {
      if (container.latest_appointment) {
        const watch: GETWatch = {
          container_number: container.container_number,
          customer: container.customer,
          state: container.cycle_state,
          master_bill_of_lading: container.master_bl,
          last_related_terminal: container.last_related_terminal,
          shipping_line: container.shipping_line,
          container_type: container.container_type,
          import_out_gated_time: container.import_out_gated_time,
          added_by_user: container.added_by_user,
          assigned_users: container.assigned_users,
          discharged_time: container.discharged_time,
          import_appointment_time: container.import_appointment_time,
          empty_appointment_time: container.empty_appointment_time,
          empty_in_gated_time: container.empty_in_gated_time,
          import_last_free_date: container.last_free_date,
          tags: container.tags,
          vessel: container.vessel,
        }
        return new AppointmentWithContainerInfo(
          {
            appointment: container.latest_appointment,
            watch,
          },
          userStore.demo_mode
        )
      } else {
        return null
      }
    }

    return {
      loading,
      appointments,
      appointmentsMatchingSearchParams,
      appointmentsByContainerNumber,
      appointmentsByID,
      load,
      clearAllData,
      updateFromContainer,
    }
  }
)

async function loadLiveData(
  params: LoadParams
): Promise<GETAppointmentWithContainer[]> {
  const api = new AppointmentsApi()
  const includeGateTransactions = false
  const includeImportStatuses = false
  // https://api.draydog.com/redoc#tag/Appointments/operation/list_appointments_with_container_info_appointments_with_container_info__get
  const response =
    await api.listAppointmentsWithContainerInfoAppointmentsWithContainerInfoGet(
      params.after.toISO(),
      params.before?.toISO(),
      params.direction,
      params.loaded,
      params.statuses,
      includeGateTransactions,
      includeImportStatuses,
      params.limitToRelevantAppointments ?? false,
      params.containers?.join(',')
    )
  return response.data
}

async function loadMockData(
  params: LoadParams
): Promise<GETAppointmentWithContainer[]> {
  // Simulate an API delay
  await new Promise((resolve) => setTimeout(resolve, 500))
  // NOTE: Not respecting params right now
  return createRandomGETAppointmentsWithContainers({
    numAppointments: 50,
    after: params.after,
    before: params.before,
    direction: params.direction,
    loaded: params.loaded,
    statuses: params.statuses,
  })
}
