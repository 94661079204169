<script setup lang="ts">
import { ElTag } from 'element-plus'
import { getStatusType, statusText } from './StatusUtils'
import { AppointmentStatus } from '~/services/apiClient'

const props = defineProps<{
  status: AppointmentStatus
}>()
</script>

<template>
  <ElTag
    :type="getStatusType(props.status)"
    round
    disable-transitions
    effect="plain"
    :class="{ scheduled: props.status === AppointmentStatus.Scheduled }"
  >
    {{ statusText(props.status) }}
  </ElTag>
</template>

<style scoped lang="scss">
$background-color: #fefcff;
$text-color: #d38af2;

.scheduled {
  background-color: $background-color !important;
  color: $text-color;
  border-color: $text-color;
}
</style>
