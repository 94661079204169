import type { ContainerType } from '~/services/apiClient'
import { TerminalName } from '~/services/apiClient'

const CONTAINER_LENGTH_REGEX = /\d+/

export function getContainerLength(containerType: string): number {
  if (!containerType) {
    throw new Error('Container type cannot be null or empty.')
  }

  const match = CONTAINER_LENGTH_REGEX.exec(containerType)
  if (match) {
    return parseInt(match[0], 10)
  }

  throw new Error(
    `Unable to extract a container length from '${containerType}'`
  )
}

export function checkForDualLengthMisMatchViolation(
  terminal: TerminalName,
  inboundType: ContainerType,
  outboundType: ContainerType
): string | undefined {
  const inboundLength = getContainerLength(inboundType)
  const outboundLength = getContainerLength(outboundType)

  if (terminal === TerminalName.Apm) {
    const validLengths = [40, 45]
    if (
      validLengths.includes(inboundLength) &&
      validLengths.includes(outboundLength)
    ) {
      return undefined
    }
  }

  if (inboundLength !== outboundLength) {
    return `At ${terminal}, dual transactions with different lengths WILL NOT be accepted. Selected lengths: ${inboundType} vs ${outboundType}.`
  }

  return undefined
}
