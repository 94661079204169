<script
  setup
  lang="ts"
  generic="T extends { id: string } & Record<string, any>"
>
import StickyHeaderVirtualScroller from './StickyHeaderVirtualScroller.vue'
import type { ColumnDef } from './types'

const props = defineProps<{
  columns: ColumnDef<T>[]
  items: T[]
  rowHeight: number
  headerHeight: number
  groupHeaderHeight: number
  subGroupHeaderHeight: number
  getGroupHeaderId: (item: T) => string
  getSubGroupHeaderId: (item: T) => string
  rowClass?: ({ rowData }: { rowData: T }) => string[]
  rowProps?: ({ rowData }: { rowData: T }) => Record<string, any>
  bufferPixels?: number
}>()

const emit = defineEmits<{
  (event: 'headerChange', headerid: string | null): void
  (event: 'subHeaderChange', subheaderid: string | null): void
}>()

// Refs
const virtualScrollerRef = ref<{ scrollToHeader: (headerID: string) => void }>()

// Expose methods to parent
defineExpose({
  scrollToHeader: (headerID: string) =>
    virtualScrollerRef.value?.scrollToHeader(headerID),
})

defineSlots<{
  default(props: { item: T }): VNode | Component
  'group-header'(props: { item: T }): VNode | Component
  'group-subheader'(props: { item: T }): VNode | Component
}>()
</script>

<template>
  <div class="virtual-table">
    <!-- Table Header -->
    <div class="table-header" :style="{ height: `${headerHeight}px` }">
      <div
        v-for="column in columns"
        :key="column.title"
        class="header-cell"
        :style="{
          minWidth: `${column.minWidth}px`,
          maxWidth: column.maxWidth ? `${column.maxWidth}px` : undefined,
          flex: column.flexGrow || 1,
        }"
      >
        {{ column.title }}
      </div>
    </div>

    <!-- Table Body -->
    <StickyHeaderVirtualScroller
      ref="virtualScrollerRef"
      :items="items"
      :row-height="rowHeight"
      :header-height="groupHeaderHeight"
      :sub-header-height="subGroupHeaderHeight"
      :get-header-id="props.getGroupHeaderId"
      :get-sub-header-id="props.getSubGroupHeaderId"
      :buffer-pixels="props.bufferPixels"
      class="table-body"
      @header-change="(headerid) => emit('headerChange', headerid)"
      @sub-header-change="(subheaderid) => emit('subHeaderChange', subheaderid)"
    >
      <template #default="{ item }">
        <div
          class="table-row"
          role="row"
          :class="[
            ...(props.rowClass ? props.rowClass({ rowData: item }) : []),
          ]"
          v-bind="props.rowProps?.({ rowData: item })"
        >
          <div
            v-for="column in columns"
            :key="column.title"
            class="table-cell"
            role="cell"
            :style="{
              minWidth: `${column.minWidth}px`,
              maxWidth: column.maxWidth ? `${column.maxWidth}px` : undefined,
              flex: column.flexGrow || 1,
            }"
          >
            <component
              :is="column.cellRenderer"
              v-if="column.cellRenderer"
              v-bind="{
                rowData: item,
                columnDef: column,
              }"
            />
            <template v-else-if="column.dataKey">
              {{ item[column.dataKey] }}
            </template>
          </div>
        </div>
      </template>

      <template #header="{ item }">
        <slot name="group-header" :item="item" />
      </template>

      <template #subheader="{ item }">
        <slot name="group-subheader" :item="item" />
      </template>
    </StickyHeaderVirtualScroller>
  </div>
</template>

<style scoped lang="scss">
$row-height: v-bind('`${props.rowHeight}px`');

.virtual-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.table-header {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  @apply text-sm text-gray-500;
}

.header-cell {
  padding: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-body {
  flex: 1;
  overflow: hidden;
}

.table-row,
.primary-header,
.sub-header {
  display: flex;
  flex-direction: row;
}
.table-row {
  @apply px-2 h-full;
  border-top: 1px solid #e0e0e0;
}

$cell-padding: 6px;
.table-cell {
  padding: 0 $cell-padding;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: $row-height !important;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
</style>
