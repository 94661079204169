import { Settings } from 'luxon'
// register vue composition api globally
import { ViteSSG } from 'vite-ssg'
import { setupLayouts } from 'virtual:generated-layouts'

import { routes } from 'vue-router/auto-routes'
import App from './App.vue'

// element-plus
import 'element-plus/dist/index.css'

// windicss layers
import 'virtual:windi-base.css'
import 'virtual:windi-components.css'
// your custom styles here
import './styles/main.scss'
import 'floating-vue/dist/style.css'
// windicss utilities should be the last style import
import 'virtual:windi.css'
import 'virtual:windi-utilities.css'
// windicss devtools support (dev only)
import 'virtual:windi-devtools'
import type { UserModule } from './types'

// if (process.env.NODE_ENV === 'development') {
//   import('../test/mockBrowser').then((m) => {
//     m.worker.start({
//       waitUntilReady: true,
//       quiet: true,
//       onUnhandledRequest: 'bypass',
//     })
//   })
// }

// Everything is in relation to PST, so let's just use PST for now
Settings.defaultZone = 'America/Los_Angeles'
Settings.throwOnInvalid = true

declare module 'luxon' {
  interface TSSettings {
    throwOnInvalid: true
  }
}

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(
  App,
  { routes: setupLayouts(routes), base: import.meta.env.BASE_URL },
  (ctx) => {
    // install all modules under `modules/`
    Object.values(
      import.meta.glob<{ install: UserModule }>('./modules/*.ts', {
        eager: true,
      })
    ).forEach((i) => i.install?.(ctx))
  }
)
