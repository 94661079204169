<script setup lang="ts">
import { useShippingLine } from '~/compositions/useShippingLines'
import { getContainerTypeDescription } from '~/constants'
import type { ContainerType, ShippingLine } from '~/services/apiClient'

interface ContainerLike {
  raw_line?: string
  raw_type?: string
  line: ShippingLine | undefined
  type: ContainerType | undefined
  type_short: string | undefined
}

const props = defineProps<{
  container: ContainerLike
}>()
const { getShippingLine } = useShippingLine()
const shippingLine = computed(() => {
  return getShippingLine(props.container.line)
})
const typeTooltip = computed(() => {
  if (props.container.type) {
    let tooltip = `${getContainerTypeDescription(props.container.type)}`
    if (props.container.raw_type) {
      tooltip += ` (raw: ${props.container.raw_type})`
    }
    return tooltip
  }
  if (props.container.raw_type) {
    return 'This is not a container type Dray Dog recognizes...yet!'
  } else {
    return "We don' have container type data for this container"
  }
})
const lineTooltip = computed(() => {
  if (shippingLine.value) {
    let tooltip = `${shippingLine.value.long_desc}`
    if (props.container.raw_line) {
      tooltip += ` (raw: ${props.container.raw_line})`
    }
    return tooltip
  }
  if (props.container.raw_line) {
    return `This is not a shipping line Dray Dog recognizes...yet!`
  } else {
    return "We don' have shipping line data for this container"
  }
})
</script>

<template>
  <span>
    <span v-tooltip="lineTooltip">
      <span v-if="shippingLine || container.line" class="text-gray-400">{{
        shippingLine?.short_desc || container.line
      }}</span
      ><span v-else-if="container.raw_line" class="text-red-900">{{
        container.raw_line
      }}</span
      ><span v-else>?</span></span
    >/<span v-tooltip="typeTooltip"
      ><span
        v-if="container.type && container.type_short"
        class="text-gray-400"
        >{{ container.type_short }}</span
      ><span v-else-if="container.raw_type" class="text-red-900">{{
        container.raw_type
      }}</span
      ><span v-else>?</span>
    </span>
    <!-- TODO Figure out how to get "Wheeled" in here for import containers -->
  </span>
</template>
