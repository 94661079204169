<template>
  <div class="primary-row">
    <div>
      <slot name="default" />
    </div>
    <div class="secondary-row truncate">
      <slot name="secondary-row" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.primary-row {
  font-size: 0.8rem;
  @apply leading-4;
}
.secondary-row {
  @apply text-gray-400 truncate;
  font-size: 0.7rem;
  // margin-top: 2px;
}
</style>
