/* eslint-disable no-console */
import { acceptHMRUpdate, defineStore } from 'pinia'
import * as Sentry from '@sentry/browser'
import { delMany, getMany, setMany } from 'idb-keyval'
import { DateTime } from 'luxon'
import { isInternalUser } from '~/constants'
import { reloadPage } from '~/utils'
import type {
  GETOrganization,
  GETToken,
  GETUserInfo,
  SimpleUser,
} from '~/services/apiClient'
import {
  OrganizationSubscription,
  UserRoleType,
  UsersApi,
} from '~/services/apiClient'
import { UserInfo } from '~/models/userInfo'
import { Organization } from '~/models/organization'
import { clearChatData, configureUserWithCrispChat } from '~/services/crispChat'
import { startOpenReplayTracking } from '~/observability/openReplay'
import { useSoketi } from '#compositions/configureSoketi'
import {
  clearAxiosAuthorizationHeader,
  setAxiosAuthorizationHeader,
} from '~/services/apiHelpers'

function clearLocalStorageExcept(keepKeys: Set<string>) {
  const allKeys = Object.keys(localStorage)
  allKeys.forEach((key) => {
    if (!keepKeys.has(key)) {
      localStorage.removeItem(key)
    }
  })
}

const AUTH_HEADER_KEY = 'auth_headers_by_org_id'
const EXPORT_IN_TRINIUM_MODE_KEY = 'export_in_trinium_mode'
const EXPORT_ALL_APPOINTMENTS_KEY = 'export_all_appointments'
const USE_OWN_CHASSIS_KEY = 'use_own_chassis'
const DEMO_MODE_KEY = 'demo_mode'
const TEST_MODE_KEY = 'test_mode'
const ALL_USER_DATA_KEYS = new Set([
  AUTH_HEADER_KEY,
  EXPORT_IN_TRINIUM_MODE_KEY,
  EXPORT_ALL_APPOINTMENTS_KEY,
  USE_OWN_CHASSIS_KEY,
  DEMO_MODE_KEY,
  TEST_MODE_KEY,
])

export const useUserStore = defineStore('user', () => {
  // "Internal" ref and loggedIn being a ref and not a computed property is to avoid
  const authHeaderInternal = useLocalStorage<null | string>('auth_header', null)
  const authHeader = ref(null as null | string)
  const loggedIn = ref(false)
  const authHeadersByOrgID = useLocalStorage<Record<string, string>>(
    AUTH_HEADER_KEY,
    {}
  )
  const exportInTriniumMode = useLocalStorage<boolean>(
    EXPORT_IN_TRINIUM_MODE_KEY,
    false
  )
  const exportAllAppointments = useLocalStorage<boolean>(
    EXPORT_ALL_APPOINTMENTS_KEY,
    false
  )
  const orgNotificationsChannel = ref<string>('')
  const userNotificationsChannel = ref<string>('')

  const useOwnChassis = useLocalStorage<boolean>(USE_OWN_CHASSIS_KEY, false)
  const loading = ref(false)
  const feature_flags = ref(new Set<string>())
  const showHideImportAppointments: Ref<boolean | undefined> = ref(true)

  const demo_mode = useLocalStorage<boolean>(DEMO_MODE_KEY, false, {})
  const testMode = useLocalStorage<boolean>(TEST_MODE_KEY, false)
  const userInfo = shallowRef(null as null | UserInfo)
  const { getLastConnectedAndDisconnected } = useSoketi()

  // Computed properties
  const userEmail = computed(() => {
    return userInfo.value?.email ?? null
  })
  const is_internal_user = computed(() => {
    if (userEmail.value) {
      return isInternalUser(userEmail.value)
    } else {
      return false
    }
  })

  // Watchers
  watch(
    authHeaderInternal,
    (newHeader) => {
      // This whole setup is here to help avoid race cases
      if (newHeader) {
        // Set header prior to updating refs
        setAxiosAuthorizationHeader(newHeader)
        loggedIn.value = true
        authHeader.value = newHeader
        identifyUserWithServices()
      } else {
        // Update refs prior to clearing header
        loggedIn.value = false
        authHeader.value = null
        clearAxiosAuthorizationHeader()
      }
    },
    { immediate: true }
  )

  // Actions
  function setAuthHeader(header: string) {
    setAxiosAuthorizationHeader(header)
    authHeaderInternal.value = header
  }

  function addAuthHeader(tokenInfo: GETToken) {
    const authHeaderVal = makeAuthHeader(tokenInfo)
    authHeadersByOrgID.value[tokenInfo.organization_id] = authHeaderVal
  }
  let loadInfoPromise: Promise<UserInfo> | null = null

  function loadInfoFromAPI(): Promise<UserInfo> {
    if (loadInfoPromise) {
      return loadInfoPromise
    }
    const api = new UsersApi()
    loadInfoPromise = api
      .getCurrentUserInfoUsersCurrentInfoGet()
      .then((resp) => {
        const userInfo = updateUser(resp.data)
        saveToStorage(userInfo)
        return userInfo
      })
      .finally(() => {
        loadInfoPromise = null
      })

    return loadInfoPromise
  }
  function updateUser(user: GETUserInfo): UserInfo {
    userInfo.value = new UserInfo(user, demo_mode.value)
    feature_flags.value = new Set<string>(userInfo.value.feature_flags)
    orgNotificationsChannel.value = `private-organization-notifications-${userInfo.value.current_organization.id}`
    userNotificationsChannel.value = `private-user-notifications-${userInfo.value.current_organization.id}-${userInfo.value.id}`
    return userInfo.value
  }

  /**
   * Loads user info from IndexDB storage (which supports large objects, which we have for
   * internal Dray Dog users) if available
   */
  const USER_INFO_KEY = 'userInfo'
  const USER_INFO_LAST_FETCHED_KEY = 'userInfoLastFetched'
  async function loadFromStorage(): Promise<UserInfo | null> {
    const [userInfo, userInfoLastFetched] = await getMany([
      USER_INFO_KEY,
      USER_INFO_LAST_FETCHED_KEY,
    ])
    if (userInfo) {
      const soketiConnectionInfo = await getLastConnectedAndDisconnected()
      const lastFetched = DateTime.fromMillis(userInfoLastFetched)
      const now = DateTime.now()
      // Force immediate refresh for very old data
      if (lastFetched < now.minus({ days: 2 })) {
        return null
      }
      let triggerAsyncRefresh = false
      // Trigger asynchronous refresh for somewhat old data
      if (lastFetched < now.minus({ hours: 6 })) {
        triggerAsyncRefresh = true
      }
      // Trigger asychronous refresh if we've disconnected recently since we loaded
      // data. The point here is if we've been offline we may have missed websocket
      // updates about our user or it's organizations
      else if (
        soketiConnectionInfo.lastDisconnected &&
        soketiConnectionInfo.lastDisconnected > lastFetched
      ) {
        triggerAsyncRefresh = true
      }
      // Trigger async refresh if we connected recently since we loaded
      else if (
        soketiConnectionInfo.lastConnected &&
        // A little buffer so immediate page reloads don't trigger a refresh, although
        // most page reloads would request a refresh
        soketiConnectionInfo.lastConnected.minus({ minutes: 1 }) > lastFetched
      ) {
        triggerAsyncRefresh = true
      }
      if (triggerAsyncRefresh) {
        console.log('Triggering async refresh of user info')
        loadInfoFromAPI()
      }
      console.log(
        `Returning cached user info loaded ${lastFetched.toRelative()}. ` +
          `lastDisconnected: ${soketiConnectionInfo.lastDisconnected?.toRelative()} ` +
          `lastConnected: ${soketiConnectionInfo.lastConnected?.toRelative()}`
      )
      return new UserInfo(userInfo, demo_mode.value)
    }
    return null
  }
  async function saveToStorage(userInfo: UserInfo) {
    // Create a plain object copy without any class instances or circular references
    const userInfoForStorage = JSON.parse(JSON.stringify(userInfo.raw))
    await setMany([
      [USER_INFO_KEY, userInfoForStorage],
      [USER_INFO_LAST_FETCHED_KEY, Date.now()],
    ])
  }

  async function clearFromStorage() {
    await delMany([USER_INFO_KEY, USER_INFO_LAST_FETCHED_KEY])
  }

  async function loadInfoIfNeeded(): Promise<UserInfo> {
    if (userInfo.value) {
      return userInfo.value
    }
    const cachedUserInfo = await loadFromStorage()
    if (cachedUserInfo) {
      updateUser(cachedUserInfo.raw)
      return cachedUserInfo
    }
    const freshUserInfo = await loadInfoFromAPI()
    return freshUserInfo
  }

  function updateOrg(orgUpdate: GETOrganization) {
    if (userInfo.value) {
      if (userInfo.value.current_organization.id === orgUpdate.id) {
        userInfo.value.current_organization = new Organization(orgUpdate)
      }
      for (const [index, org] of userInfo.value.organizations.entries()) {
        if (org.id === orgUpdate.id) {
          userInfo.value.organizations[index] = org
        }
      }
    }
  }

  async function switchToOrg(orgID: number): Promise<void> {
    // unbind from the channels
    // unbindFromUserChannels()
    if (!authHeadersByOrgID.value[orgID]) {
      const api = new UsersApi()
      const resp = await api.getTokenViaTokenUsersTokenViaTokenPost({
        organization_id: orgID,
      })
      authHeadersByOrgID.value[orgID] = makeAuthHeader(resp.data)
    }
    clearLocalStorageExcept(ALL_USER_DATA_KEYS)
    setAuthHeader(authHeadersByOrgID.value[orgID])
    await loadInfoFromAPI()
    reloadPage()
  }

  async function login(tokenResp: GETToken) {
    const authHeader = makeAuthHeader(tokenResp)
    setAuthHeader(authHeader)
    demo_mode.value = false
    await loadInfoFromAPI()
  }
  async function loginUsingPortOptimizerToken(token: string): Promise<void> {
    const api = new UsersApi()
    return await api
      .getTokenViaPortOptimizerTokenUsersTokenViaPortOptimizerTokenPost({
        token,
      })
      .then((resp) => {
        return login(resp.data)
      })
  }
  function clearAllUserData() {
    authHeaderInternal.value = null
    userInfo.value = null
    localStorage.clear()
    feature_flags.value = new Set<string>()
    clearFromStorage()
  }

  function logout() {
    clearAxiosAuthorizationHeader()
    clearAllUserData()
    clearChatData()
    // @ts-expect-error
    if (window.posthog) {
      // @ts-expect-error
      posthog.reset()
    }
  }
  const userAlreadyIdentified = ref(false)
  async function identifyUserWithServices() {
    if (!userInfo.value) return
    // Avoid duplicate calls to these services. If our page is refreshed, this would
    // be called again.
    if (userAlreadyIdentified.value) return
    const email = userInfo.value?.email
    if (import.meta.env.DEV) {
      return
    }
    configureUserWithCrispChat(userInfo.value)
    if (isInternalUser(email)) {
      return
    }
    const openReplayModule = await import('~/observability/openReplay')
    const openReplay = openReplayModule.getOpenReplayTracker()
    openReplay.setUserID(email)
    startOpenReplayTracking().then(() => {
      openReplay.setUserID(email)
    })
    // Identify in PostHog. PostHog is not available on localhost
    // @ts-expect-error
    if (window.posthog) {
      // Delay is to give PostHog time to initialize
      setTimeout(() => {
        // @ts-expect-error
        posthog.identify(email, {
          properties: {
            email,
          },
        })
      }, 2000)
    } else {
      console.log('PostHog not available')
    }
    // Identify in Sentry (error tracking)
    Sentry.setUser({ email })
    userAlreadyIdentified.value = true
  }

  function setShowHideImportAppt(value: boolean | undefined) {
    showHideImportAppointments.value = value
  }

  // Getters
  const token = computed((): string | undefined => {
    if (authHeader.value) {
      return authHeader.value.split(' ')[1]
    }
    return undefined
  })
  const currentRole = computed((): UserRoleType | null => {
    return userInfo.value?.current_organization.role ?? null
  })
  const isSubCarrier = computed((): boolean => {
    return currentRole.value === UserRoleType.SubCarrier
  })
  const hasManagementPermissions = computed((): boolean => {
    return (
      currentRole.value === UserRoleType.Management ||
      (userEmail.value !== null && isInternalUser(userEmail.value))
    )
  })
  const loggedInUser = computed((): SimpleUser | null => {
    if (userInfo.value) {
      return {
        id: userInfo.value.id,
        first_name: userInfo.value.first_name,
        last_name: userInfo.value.last_name,
      }
    }
    return null
  })

  const isTerminalOrg = computed((): boolean => {
    if (userInfo.value) {
      // if its a terminal subscription
      const terminal_org =
        userInfo.value.current_organization.subscriptions.includes(
          OrganizationSubscription.Terminal
        )
      return terminal_org
    }
    return false
  })

  const isUasOrg = computed((): boolean => {
    if (userInfo.value) {
      // if its a UAS subscription
      const uas_org =
        userInfo.value.current_organization.subscriptions.includes(
          OrganizationSubscription.Uas
        )
      return uas_org
    }
    return false
  })
  const currentOrg = computed((): Organization | undefined => {
    return userInfo.value?.current_organization
  })

  const getShowHideImportAppt = computed((): boolean | undefined => {
    return showHideImportAppointments.value
  })

  return {
    // Refs
    userEmail,
    authHeader,
    exportInTriniumMode,
    exportAllAppointments,
    useOwnChassis,
    loading,
    feature_flags,
    demo_mode,
    testMode,
    userInfo,
    is_internal_user,
    showHideImportAppointments,
    orgNotificationsChannel,
    userNotificationsChannel,
    // Actions
    addAuthHeader,
    switchToOrg,
    loadInfoIfNeeded,
    login,
    logout,
    identifyUserWithTrackers: identifyUserWithServices,
    loadInfoFromAPI,
    updateUser,
    updateOrg,
    setShowHideImportAppt,
    loginUsingPortOptimizerToken,
    // Computed properties
    loggedIn,
    token,
    isSubCarrier,
    hasManagementPermissions,
    loggedInUser,
    currentOrg,
    getShowHideImportAppt,
    isTerminalOrg,
    isUasOrg,
  }
})

function makeAuthHeader(tokenResp: GETToken): string {
  return `${tokenResp.token_type} ${tokenResp.access_token}`
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
