<script setup lang="ts">
import { Plus } from '@element-plus/icons-vue'
import SwapEmptyDialog from '../dialogs/SwapEmptyDialog.vue'
import AddEmptyDialog from '../dialogs/AddEmptyDialog.vue'
import MoveTypeIcon from './MoveTypeIcon.vue'
import TwoRowCell from './TwoRowCell.vue'
import DualsIcon from '~/components/icons/DualsIcon.vue'
import ClickToCopy from '~/components/ClickToCopy.vue'
import type { AppointmentTransaction } from '~/models/groupedAppointments'
import { AppointmentStatus } from '~/services/apiClient'
import { useUserStore } from '~/stores/user'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import ContainerLineAndType from '~/components/display/ContainerLineAndType.vue'
const props = defineProps<{
  containerInfo: AppointmentWithContainerInfo | undefined
  isDual: boolean
  transaction: AppointmentTransaction
  rowIsHovered: boolean
}>()

const swapEmptyDialogOpen = ref(false)
const addEmptyDialogOpen = ref(false)

function openSwapEmptyDialog() {
  swapEmptyDialogOpen.value = true
}
const userStore = useUserStore()
const showReturnRules = computed(() => {
  return !userStore.isUasOrg
})

const appointment = computed(() => {
  return props.containerInfo?.appointment
})
const showSwapEmptyButton = computed(() => {
  return (
    appointment.value &&
    appointment.value.is_empty_in &&
    props.rowIsHovered &&
    props.isDual
  )
})
function openAddEmptyDialog() {
  addEmptyDialogOpen.value = true
}
const showAddEmptyButton = computed(() => {
  return (
    appointment.value === undefined &&
    props.transaction.primary_appointment?.appointment.is_import_out &&
    props.transaction.primary_appointment?.appointment.status ===
      AppointmentStatus.Scheduled
  )
})

const importAppointment = computed(() => {
  if (props.transaction.primary_appointment?.appointment.is_import_out) {
    return props.transaction.primary_appointment?.appointment
  }
})
</script>

<template>
  <div class="relative w-full">
    <TwoRowCell v-if="containerInfo" class="leading-5">
      <MoveTypeIcon
        :appointment="containerInfo.appointment"
        class="container-icon"
      />
      <ClickToCopy :text-to-copy="containerInfo.appointment.container_number" />
      <el-button
        v-if="showSwapEmptyButton"
        v-tooltip="`Swap empty in container number`"
        size="small"
        circle
        type="primary"
        class="mr-2"
        style="position: absolute; top: 0px; right: -12px; z-index: 10"
        @click="openSwapEmptyDialog"
      >
        <i-mdi:pencil />
      </el-button>
      <DualsIcon v-if="props.isDual" class="duals-icon" />
      <template #secondary-row>
        <ContainerLineAndType :container="containerInfo.watch" />
        <template v-if="containerInfo.watch.customer_name">
          •
          <span v-tooltip="`Customer is ${containerInfo.watch.customer_name}`">
            {{ containerInfo.watch.customer_name }}
          </span>
        </template>
      </template>
    </TwoRowCell>
    <div v-else>
      <ElButton
        v-if="showAddEmptyButton"
        v-tooltip="`Add empty`"
        type="primary"
        size="small"
        plain
        :icon="Plus"
        class="w-full"
        @click="openAddEmptyDialog"
      >
        Add Empty
      </ElButton>
    </div>
  </div>

  <SwapEmptyDialog
    v-if="swapEmptyDialogOpen && appointment"
    :appointment="appointment"
    :show-return-rules="showReturnRules"
    @close="swapEmptyDialogOpen = false"
  />

  <AddEmptyDialog
    v-if="addEmptyDialogOpen && showAddEmptyButton && importAppointment"
    :container="props.transaction.primary_appointment.watch"
    :appointment="importAppointment"
    :show-return-rules="showReturnRules"
    @close="addEmptyDialogOpen = false"
  />
</template>

<style scoped lang="scss">
svg {
  @apply align-text-bottom;
}
.container-icon {
  margin-right: 2px;
}
.duals-icon {
  right: 0px;
  margin-top: 1px;
  position: absolute;
}
</style>
