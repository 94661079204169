<script lang="ts" setup>
import { DateTime } from 'luxon'
import EmptyContainerSelectorOption from './EmptyContainerSelectorOption.vue'
import type { ContainerType, ShippingLine } from '~/services/apiClient'
import { ContainerWatchState } from '~/services/apiClient'
import { usedBasicContainersStore } from '~/stores/basicContainers'
import type { BasicContainer } from '~/models/containers'

interface HasLineAndType {
  shipping_line: ShippingLine
  container_type: ContainerType
}
const props = defineProps<{
  modelValue: string
  limitToLineAndTypes?: HasLineAndType[]
}>()
// Events
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | undefined): void
}>()
// Stores
const selectedContainerNumber = useVModel(props, 'modelValue', emit)
const basicContainersStore = usedBasicContainersStore()
// Refs
const availableEmpties = toRef(basicContainersStore, 'containers')
const autoSelectedValue = ref(null as null | string)
const loadingAvailableEmpties = ref(basicContainersStore.loading)
const inputRef = ref()
// Computed
function typeAndLineKey(
  shipping_line: ShippingLine,
  container_type: ContainerType
) {
  return `${shipping_line}-${container_type}`
}
function typeAndLineKeyFromObject(item: HasLineAndType) {
  return typeAndLineKey(item.shipping_line, item.container_type)
}
const returnableKeys = computed((): Set<string> | undefined => {
  if (!props.limitToLineAndTypes) {
    return undefined
  }
  const keys = new Set<string>()
  for (const item of props.limitToLineAndTypes) {
    keys.add(typeAndLineKeyFromObject(item))
  }
  return keys
})
const uniqueShippingLines = computed((): ShippingLine[] | undefined => {
  if (!props.limitToLineAndTypes) {
    return undefined
  }
  const lines = new Set<ShippingLine>()
  for (const { shipping_line: line } of props.limitToLineAndTypes) {
    lines.add(line)
  }
  return Array.from(lines)
})
const uniqueContainerTypes = computed((): ContainerType[] | undefined => {
  if (!props.limitToLineAndTypes) {
    return undefined
  }
  const types = new Set<ContainerType>()
  for (const { container_type: type } of props.limitToLineAndTypes) {
    types.add(type)
  }
  return Array.from(types)
})
const nonUserSetValue = computed(() => {
  return autoSelectedValue.value
})
function loadAvailableEmpties() {
  basicContainersStore
    .load({
      states: [
        ContainerWatchState.ImportPickedUp,
        ContainerWatchState.ImportEmpty,
      ],
      // Crudely limit the empties to the applicable lines and types
      // We will eliminate the rest in JavaScript
      shippingLines: uniqueShippingLines.value,
      containerTypes: uniqueContainerTypes.value,
      // Don't show really old out-gated empties as we may have just missed their
      // in-gates
      importOutGatedAfter: DateTime.now().minus({ days: 14 }),
      // Don't include containers out-gated today, they are probably not ready yet
      importOutGatedBefore: DateTime.now().minus({ days: 1 }),
    })
    .then(() => {
      basicContainersStore.sortByOutGateTime()
      if (inputRef.value) {
        inputRef.value.focus()
      }
    })
}
function fetchSuggestions(
  input: string,
  callback: (options: BasicContainer[]) => void
): void {
  let results: BasicContainer[] = availableEmpties.value || []
  // Filter out unreturnable containers
  const localReturnableKeys = returnableKeys.value
  if (localReturnableKeys) {
    results = results.filter((container) => {
      if (container.container_type && container.shipping_line) {
        const key = typeAndLineKey(
          container.shipping_line,
          container.container_type
        )
        if (!localReturnableKeys.has(key)) {
          return false
        }
      }
      return true
    })
  }
  // Filter based on search text
  input = input.trim().toLocaleUpperCase()
  if (
    input.length > 0 &&
    (!nonUserSetValue.value || input !== nonUserSetValue.value)
  ) {
    results = results.filter((container) => {
      // filter number, shipping line, and container type
      return (
        container.number.toUpperCase().includes(input) ||
        (container.container_type ?? '').toUpperCase().includes(input) ||
        (container.shipping_line ?? '').toUpperCase().includes(input) ||
        (container.customer_name ?? '').toUpperCase().includes(input)
      )
    })
  }
  callback(results)
}
onMounted(() => {
  if (!props.modelValue) {
    loadAvailableEmpties()
  }
})
</script>

<template>
  <el-autocomplete
    ref="inputRef"
    v-model="selectedContainerNumber"
    :loading="loadingAvailableEmpties"
    :fetch-suggestions="fetchSuggestions"
    value-key="number"
    clearable
    :select-when-unmatched="true"
    :highlight-first-item="!nonUserSetValue"
    :debounce="50"
    placeholder="Enter a container number"
    size="large"
  >
    <template #prefix>
      <i-charm:container />
    </template>
    <template #default="{ item: container }">
      <EmptyContainerSelectorOption
        v-if="container"
        :container="container as BasicContainer"
      />
    </template>
  </el-autocomplete>
</template>
