<script setup lang="ts">
import type { AppointmentTableRow } from './types'
const props = defineProps<{
  rowData: AppointmentTableRow
}>()
const appointment = computed(
  () => props.rowData.transaction?.primary_appointment
)
const shiftRealDateMismatch = computed(() => {
  return (
    appointment.value?.appointment.window_start.toISODate() !==
    appointment.value?.appointment.shiftDate.toISODate()
  )
})
const dateTooltip = computed(() => {
  if (shiftRealDateMismatch.value) {
    const shiftDate = appointment.value?.appointment.shiftDate.toFormat('M/d')
    const realDate = appointment.value?.appointment.window_start.toFormat('M/d')
    return `Shift date is ${shiftDate} but "real" date is ${realDate}`
  }
  return null
})
</script>

<template>
  <div v-if="appointment" data-testid="time-cell">
    <span v-tooltip="dateTooltip" class="text-gray-500 mr-1">
      {{ appointment.appointment.window_start.toFormat('M/d') }}
    </span>
    <span> {{ appointment.appointment.window_start_description }}</span
    ><span class="text-gray-500">
      - {{ appointment.appointment.window_end_description }}
    </span>
  </div>
  <div v-else></div>
</template>
