<script lang="ts" setup>
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessage, ElNotification } from 'element-plus'
import { useConnectionsStore } from '~/stores/connections'
import { type GETConnection } from '~/services/apiClient'

const props = defineProps<{
  connection: GETConnection
  tmsName: string
  documentationLink: string | undefined
  formRules: FormRules | undefined
}>()
const conn = toReactive(props.connection)
const config = toReactive(conn.config)
const formRef = ref<FormInstance>()
const savedConfigJSON = ref(JSON.stringify(conn.config))
const isModified = computed(() => {
  return savedConfigJSON.value !== JSON.stringify(conn.config)
})
const connectionStore = useConnectionsStore()
async function save() {
  const isValid = await formRef.value!.validate()
  if (!isValid) {
    ElMessage.warning('Please correct form errors')
    return
  }
  savedConfigJSON.value = JSON.stringify(conn.config)
  connectionStore.update(conn).then(() => {
    ElNotification({
      title: 'Success',
      message: `${props.tmsName} connection saved`,
      type: 'success',
    })
    connectionStore.load()
  })
}
const connectionSuffix = computed(() => {
  // if the config is of type SFTP config then return 'SFTP' else return 'FTP'

  if (props.tmsName === 'Trinium' || props.tmsName === 'TranData') {
    return 'SFTP Connection'
  } else {
    return 'Connection'
  }
})
</script>

<template>
  <div class="border-2 bg-gray-100 py-4 px-8 max-w-700px">
    <header class="mb-6">
      <h2 class="text-center">{{ tmsName }} {{ connectionSuffix }}</h2>
      <p
        v-if="documentationLink"
        class="text-center underline text-sm mt-2 text-gray-500"
      >
        <a
          :href="documentationLink"
          target="_blank"
          data-testid="documentation-link"
          >📚 documentation
        </a>
      </p>
    </header>
    <el-form
      ref="formRef"
      :model="config"
      label-width="auto"
      :rules="$props.formRules"
    >
      <slot></slot>
      <el-button
        v-tooltip="isModified ? 'Click to save' : 'you'"
        type="success"
        :disabled="!isModified"
        class="ml-84"
        @click="save"
      >
        Save
      </el-button>
    </el-form>
  </div>
</template>
