<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { TerminalName } from '~/services/apiClient'
import { useReturnRulesReadingsLatest } from '~/stores/returnRulesReadingsLatest'
import { useReturnRulesHistory } from '~/stores/returnRuleReadingsHistory'

import { EMPTY_RETURN_RULE_SOURCES } from '~/components/emptyReturnRules/terminals'
import type { ReturnRulesReading } from '~/models/returnRules'
import { contentAreaHeightInjectionKey } from '~/constants'

const props = defineProps<{ terminal: TerminalName }>()
const terminalRef = toRef(props, 'terminal')
const selectedHistoricalReading = ref(null as null | ReturnRulesReading)

const emptyReturnRulesHistoryStore = useReturnRulesHistory()
const latestReturnRulesStore = useReturnRulesReadingsLatest()
const reading = computed(() => {
  if (selectedHistoricalReading.value) return selectedHistoricalReading.value
  return latestReturnRulesStore.readings_by_terminal.get(props.terminal) || null
})
const source = computed(() => {
  return EMPTY_RETURN_RULE_SOURCES.find(
    (source) => source.terminal === props.terminal
  )!
})
const contentAreaHeight = inject(contentAreaHeightInjectionKey) as Ref<number>

const displayHeight = computed(() => {
  return contentAreaHeight.value - 230 + 'px'
})

function loadReadingsHistory() {
  emptyReturnRulesHistoryStore.load({
    terminalName: props.terminal,
    observedAfter: DateTime.now().minus({ days: 90 }),
  })
}
onMounted(loadReadingsHistory)
watch([terminalRef], () => {
  selectedHistoricalReading.value = null
  loadReadingsHistory()
})
</script>

<template>
  <div class="flex my-4 w-full">
    <!-- List of versions (history) -->
    <div class="ml-3 mt-2 pr-10px mr-0 min-w-82">
      <h1 class="text-2xl font-semibold ml-8 mb-4">
        <i-mdi:history class="align-middle mb-2px" />
        Return Rules History
      </h1>
      <ReturnRulesReadingsHistory
        :selected-reading="selectedHistoricalReading"
        :style="{ height: displayHeight }"
        @reading-clicked="selectedHistoricalReading = $event"
      />
    </div>
    <!-- Screenshot/reading -->
    <ReturnRulesReadingDisplay
      v-if="reading"
      :reading="reading"
      :rules-source="source"
      :reading-is-historical="Boolean(selectedHistoricalReading)"
      :style="{ height: displayHeight }"
      class="flex-1"
    />
  </div>
</template>
