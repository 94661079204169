<script lang="ts" setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import useClipboard from 'vue-clipboard3/dist/esm/index'
import { ArrowDown } from '@element-plus/icons-vue'
import { storeToRefs } from 'pinia'
import DownloadAppointmentsDialog from './dialogs/DownloadAppointmentsDialog.vue'
import DownloadContainersDialog from './dialogs/DownloadContainersDialog.vue'
import { useContainerStore } from '~/stores/containers'
import { useCancelAppointments } from '~/compositions/cancelAppointments'
import { useUserStore } from '~/stores/user'
import { useContainerFilters } from '~/compositions/containerFilters'

// Refs
const selectAll = ref(false)
const assignUserDialogOpen = ref(false)
const editCustomerDialogOpen = ref(false)
const unassignUserDialogOpen = ref(false)
const exportDialogOpen = ref(false)
const downloadContainersDialogOpen = ref(false)
const autoBookDialogOpen = ref(false)
const tagDialogOpen = ref(false)
const checkboxKey: Ref<number> = ref(0)

// Stores
const containerStore = useContainerStore()
const { page, setPage, statusFilter, pageSize } = useContainerFilters()
const userStore = useUserStore()

const { loading } = storeToRefs(containerStore)

// Computed properties
const numContainers = computed(() => {
  let count = 0
  if (statusFilter.value === undefined) {
    // Sum all status counts when we are not filtering by status
    for (const statusCount of containerStore.statusCounts.values()) {
      count += statusCount || 0
    }
  } else {
    for (const state of statusFilter.value.states) {
      count += containerStore.statusCounts.get(state) || 0
    }
  }
  return count
})
const selectedContainerNumbers = computed(() => {
  return containerStore.selectedContainerObjects.map((c) => c.number)
})
const numContainersBeingLoaded = computed(() =>
  Math.min(
    numContainers.value - (page.value - 1) * pageSize.value,
    pageSize.value
  )
)

const someContainersSelected = computed(
  () => containerStore.selectedContainerNumbers.length > 0
)
const menuTooltip = computed(() => {
  if (containerStore.selectedContainerNumbers.length > 0) {
    return `Perform actions ${containerStore.selectedContainerNumbers.length} on selected containers`
  } else {
    return 'Select some containers to perform actions'
  }
})

// Functions
const { toClipboard } = useClipboard()
async function copyContainerNumbers() {
  if (!ensureSelectedContainers()) return
  const containers = containerStore.selectedContainerNumbers.join('\n')
  try {
    await toClipboard(
      containers,
      document.getElementById('copy-selected-container-numbers') || undefined
    )
    ElMessage({
      message: `Copied ${containerStore.selectedContainerNumbers.length} container #'s to clipboard`,
      type: 'success',
    })
  } catch (e) {
    ElMessage({
      message: `Failed to copy to clipboard: ${JSON.stringify(e)}`,
      type: 'error',
    })
  }
}

function ensureSelectedContainers() {
  if (!someContainersSelected.value) {
    ElMessage.warning('You must select one or more containers')
    return false
  } else {
    return true
  }
}

function openUnassignUsers() {
  if (containerStore.assignedUsersForSelectedContainers.length === 0) {
    ElMessage.warning(
      'You must select one or more containers with assigned users'
    )
  } else {
    unassignUserDialogOpen.value = true
  }
}
const { cancelAppointments } = useCancelAppointments()
function triggerCancelAppointments() {
  if (containerStore.numAppointedContainersForSelection === 0) {
    ElMessage.warning('You must select one or more appointed containers')
    return
  }
  cancelAppointments(containerStore.selectedContainerAppointments)
}

function stopTracking() {
  if (!ensureSelectedContainers()) return
  ElMessageBox.confirm(
    `Once you've done this, you will no longer see this container. You can always start tracking again.`,
    {
      confirmButtonText: 'Stop Tracking',
      cancelButtonText: 'Cancel',
      type: 'warning',
      title: `Stop tracking ${containerStore.selectedContainerNumbers.length} containers?`,
    }
  ).then(() => {
    containerStore.removeContainers(containerStore.selectedContainerNumbers)
  })
}

function openAssignUsersDialog() {
  if (!ensureSelectedContainers()) return
  assignUserDialogOpen.value = true
}

function openEditCustomerDialog() {
  if (!ensureSelectedContainers()) return
  editCustomerDialogOpen.value = true
}

function openAutoBookDialog() {
  if (!ensureSelectedContainers()) return
  autoBookDialogOpen.value = true
}

function onClickOpenTagDialog() {
  if (!ensureSelectedContainers()) return
  tagDialogOpen.value = true
}

// Watchers
watch(selectAll, () => {
  if (selectAll.value) {
    containerStore.selectAllContainers()
  } else {
    containerStore.clearContainerSelection()
  }
})
// Reset to first page if we are off the end of the list
watch([numContainers, page], () => {
  if (numContainers.value <= pageSize.value * (page.value - 1)) {
    setPage(1)
  }
})

watch(loading, () => {
  selectAll.value = false
  checkboxKey.value++
  containerStore.clearContainerSelection()
})

// TODO: watch real loading from state in order to reset the select all button!
</script>

<template>
  <div class="flex justify-between items-center">
    <el-checkbox
      :key="checkboxKey"
      v-model="selectAll"
      label="Select All"
      size="large"
      :disabled="loading"
    />
    <!-- Center -->
    <div class="flex items-center">
      <span v-if="containerStore.loading" class="text-xs text-gray-400 italic">
        <LoadingSpinner class="align-text-bottom mb-1px" />
        Loading ({{ containerStore.containers.length }} of
        {{ numContainersBeingLoaded }})
      </span>
      <template v-else-if="numContainers > pageSize">
        <div class="text-xs text-gray-600">Page:</div>
        <el-pagination
          :current-page="page"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="numContainers"
          class="mr-2 inline-block"
          size="small"
          @current-change="setPage"
        />
      </template>
    </div>

    <el-dropdown v-tooltip="menuTooltip" trigger="click">
      <el-button
        type="success"
        class="mr-2 fs-open-actions-menu"
        style="margin-right: 0px"
      >
        Actions
        <el-icon class="el-icon--right"><ArrowDown /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <!-- Auto Book -->
          <el-dropdown-item @click="openAutoBookDialog">
            <RobotIcon color="default" class="mr-2" />
            Auto Book
          </el-dropdown-item>

          <!-- Copy Container Numbers -->
          <el-dropdown-item
            id="copy-selected-container-numbers"
            class="fs-copy-container-numbers"
            @click="copyContainerNumbers"
          >
            <i-ic:round-content-copy class="mr-2" />
            Copy Container #'s
          </el-dropdown-item>
          <!-- Customer -->
          <el-dropdown-item
            class="fs-open-edit-customer"
            @click="openEditCustomerDialog"
          >
            <i-mdi:handshake-outline class="mr-2" />
            Edit Customer
          </el-dropdown-item>
          <!-- Tag -->
          <el-dropdown-item
            class="fs-open-assign-users"
            @click="onClickOpenTagDialog"
          >
            <i-mdi:tag-outline class="mr-2" />

            Tag
          </el-dropdown-item>
          <!-- Assign -->
          <el-dropdown-item
            class="fs-open-assign-users"
            @click="openAssignUsersDialog"
          >
            <i-ant-design:user-add-outlined class="mr-2" />
            Assign
          </el-dropdown-item>

          <!-- Unassign -->
          <el-dropdown-item
            v-if="!userStore.isSubCarrier"
            class="fs-open-unassign-users"
            @click="openUnassignUsers"
          >
            <i-ant-design:user-delete-outlined class="mr-2" />
            Unassign
          </el-dropdown-item>

          <!-- Cancel Appointments -->
          <el-dropdown-item
            class="fs-open-cancel-appointments"
            @click="triggerCancelAppointments"
          >
            <i-mdi:calendar-remove class="mr-2" />
            Cancel Appointments
          </el-dropdown-item>

          <!-- Stop Tracking Containers -->
          <el-dropdown-item
            v-if="!userStore.isSubCarrier"
            class="fs-stop-tracking-containers"
            @click="stopTracking"
          >
            <i-ep:close-bold class="mr-2" />
            Stop Tracking (Archive)
          </el-dropdown-item>

          <!-- Download Appointments -->
          <el-dropdown-item
            class="fs-export-to-csv"
            @click="exportDialogOpen = true"
          >
            <i-eva:cloud-download-outline class="mr-2" />
            Download Appointments
          </el-dropdown-item>

          <!-- Download -->
          <el-dropdown-item
            class="fs-export-to-csv"
            @click="downloadContainersDialogOpen = true"
          >
            <i-eva:cloud-download-outline class="mr-2" />
            Download Container Data
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <AssignUsersDialog
      v-if="assignUserDialogOpen || unassignUserDialogOpen"
      :unassign="unassignUserDialogOpen"
      @close=";(assignUserDialogOpen = false), (unassignUserDialogOpen = false)"
    />
  </div>
  <!-- Dialogs -->
  <DownloadAppointmentsDialog
    v-if="exportDialogOpen"
    @close="exportDialogOpen = false"
  />
  <DownloadContainersDialog
    v-if="downloadContainersDialogOpen"
    :containers="containerStore.selectedContainerNumbers"
    @close="downloadContainersDialogOpen = false"
  />

  <AutoBookDialog
    v-if="autoBookDialogOpen"
    :containers="containerStore.selectedContainerObjects"
    @close="() => (autoBookDialogOpen = false)"
  />
  <EditAssignedCustomerDialog
    v-if="editCustomerDialogOpen"
    v-model="editCustomerDialogOpen"
    :containers="containerStore.selectedContainerObjects"
  />

  <TagDialog
    v-if="tagDialogOpen"
    :container-numbers="selectedContainerNumbers"
    @close="() => (tagDialogOpen = false)"
  />
</template>
