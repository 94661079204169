<script lang="ts" setup>
import Help from '../display/Help.vue'
import { TERMINALS, getTerminalLabel } from '~/constants'

const props = defineProps<{
  tmsName: string
  documentationLink: string | undefined
  modelValue: // { [key: string]: string; }
  { [key: string]: string } | undefined
}>()

const emit = defineEmits<{
  (
    e: 'update:modelValue',
    value: { tms_terminal_mapping: { [key: string]: string } | undefined }
  ): void
}>()
const mapping = useVModel(props, 'modelValue', emit)
</script>

<template>
  <el-form-item
    label="TMS Terminal Code Mapping:"
    data-testid="tms-terminal-mapping"
  >
    <div v-for="terminal in TERMINALS" :key="terminal.name">
      <span class="inline-block w-100px mt-3 mr-2"
        >{{ getTerminalLabel(terminal.name) }}:
      </span>
      <!-- @vue-ignore -->
      <el-input
        v-model="mapping[terminal.name]"
        class="max-w-400px mr-2"
        style="width: 100px"
      />
    </div>

    <Help title="TMS Terminal Code Mapping" class="ml-2"
      >Enter the terminal code or name as it shows in your TMS, we will send
      this code as the terminal when we enter EMPTY appointment times into
      {{ props.tmsName }} to update the return location.
      <a
        v-if="props.documentationLink"
        :href="props.documentationLink"
        target="_blank"
      >
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
</template>
