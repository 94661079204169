<script setup lang="ts">
import { ArrowDown } from '@element-plus/icons-vue'
import TerminalSelector from './TerminalSelector.vue'
import type { AppointmentFilters, MoveTypeFilter } from './types'
import MoveTypeFilterSelector from './MoveTypeFilterSelector.vue'
import StatusSelector from './StatusSelector.vue'
import TruckSelector from '~/components/inputs/TruckSelector.vue'
import ContainersSearch from '~/components/inputs/ContainersSearch.vue'
import CustomerSelector from '~/components/inputs/CustomerSelector.vue'
import type { AppointmentStatus, TerminalName } from '~/services/apiClient'
import type { Truck } from '~/models/trucks'
import { useUserStore } from '~/stores/user'

const props = withDefaults(
  defineProps<{
    modelValue: AppointmentFilters
    showOnlyLaTerminals?: boolean
    showCustomerFilter?: boolean
  }>(),
  { showCustomerFilter: false }
)

const emit = defineEmits<{
  (event: 'update:modelValue', filters: AppointmentFilters): void
}>()

const userStore = useUserStore()

const selectedTags = computed({
  get: () => props.modelValue.selectedTags || [],
  set: (newTags: string[]) => {
    emit('update:modelValue', {
      ...props.modelValue,
      selectedTags: newTags,
    })
  },
})

function onTerminalChanged(terminals: TerminalName[]) {
  emit('update:modelValue', { ...props.modelValue, terminals })
}
function onTruckChanged(truck: Truck | null) {
  emit('update:modelValue', {
    ...props.modelValue,
    licensePlate: truck?.licensePlate || undefined,
  })
}

function onMoveTypeChanged(moveType: MoveTypeFilter | null) {
  emit('update:modelValue', {
    ...props.modelValue,
    moveType: moveType || undefined,
  })
}

function onContainersChanged(containers: string[]) {
  emit('update:modelValue', {
    ...props.modelValue,
    containers: new Set(containers),
  })
}

function onCustomerChanged(customerId: number | undefined) {
  emit('update:modelValue', {
    ...props.modelValue,
    customerId: customerId || undefined,
  })
}

function clearSelectedTags() {
  emit('update:modelValue', { ...props.modelValue, selectedTags: [] })
}

function onStatusesChanged(statuses: AppointmentStatus[]) {
  emit('update:modelValue', { ...props.modelValue, statuses })
}
</script>

<template>
  <div class="appointment-filters">
    <el-form label-position="top" :inline="true" size="default">
      <el-form-item>
        <ContainersSearch
          :model-value="Array.from(modelValue.containers)"
          @update:model-value="onContainersChanged"
        />
      </el-form-item>
      <el-form-item>
        <MoveTypeFilterSelector
          :model-value="modelValue.moveType"
          @update:model-value="onMoveTypeChanged"
        />
      </el-form-item>
      <el-form-item>
        <TerminalSelector
          :model-value="modelValue.terminals"
          :show-only-la-terminals="props.showOnlyLaTerminals"
          style="width: 120px"
          @update:model-value="onTerminalChanged"
        />
      </el-form-item>
      <el-form-item>
        <StatusSelector
          :model-value="modelValue.statuses"
          @update:model-value="onStatusesChanged"
        />
      </el-form-item>
      <el-form-item class="-mt-1px">
        <TruckSelector
          :license-plate="modelValue.licensePlate || ''"
          :load-usage-counts="false"
          :narrow-width="false"
          @update:model-value="onTruckChanged"
        />
      </el-form-item>
      <el-form-item v-if="!userStore.isSubCarrier">
        <CustomerSelector
          v-if="props.showCustomerFilter"
          v-tooltip="'View appointments for a specific customer'"
          :model-value="modelValue.customerId"
          style="width: 150px"
          class="dropdown-filter"
          size="default"
          @update:model-value="onCustomerChanged"
        />
      </el-form-item>
      <!-- More Filters -->
      <el-popover
        :width="440"
        trigger="click"
        placement="bottom"
        :persistent="false"
        :hide-after="0"
      >
        <template #reference>
          <el-button
            :class="{ 'is-active': modelValue.selectedTags.length > 0 }"
            class="ml-2"
            size="default"
          >
            <i-mdi:filter-outline class="align-text-top h-5 w-5 mr-1" />
            More Filters
            <el-icon class="el-icon--right"><ArrowDown /></el-icon>
          </el-button>
        </template>
        <el-form-item label="Tags:" style="margin-bottom: 5px">
          <template #label>
            <i-mdi:tag class="align-middle" height="20" />
            Has (all) Tags:
          </template>
          <TagsSelector v-model="selectedTags" :clearable="true" />
        </el-form-item>
      </el-popover>
      <clearFilterButton
        v-if="modelValue.selectedTags.length"
        size="default"
        @click="clearSelectedTags"
      >
        {{ modelValue.selectedTags.join(', ') }}
      </clearFilterButton>
    </el-form>
  </div>
</template>

<style lang="scss">
.appointment-filters {
  .el-form-item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .el-form-item:last-child {
    margin-right: 0;
  }
}
</style>
