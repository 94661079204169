import type { Appointment } from '~/models/appointments'
import type { AppointmentStatus, TerminalName } from '~/services/apiClient'
import { TransactionDirection } from '~/services/apiClient'
import type {
  AppointmentTransaction,
  ShiftGroup,
} from '~/models/groupedAppointments'
import type { BookingRequestState } from '~~/stores/mutationRequests'

export class AppointmentTableRow {
  id: string
  shiftGroup: ShiftGroup
  transaction: AppointmentTransaction
  rowKey: string

  constructor(shiftGroup: ShiftGroup, transaction: AppointmentTransaction) {
    this.shiftGroup = shiftGroup
    this.transaction = transaction
    this.rowKey = String(
      transaction?.primary_appointment.appointment.id || shiftGroup.key
    )
    this.id = this.rowKey
  }

  get reschedulingKey(): string {
    return 'reschedule-' + this.rowKey
  }

  get isHeader() {
    return this.transaction === null
  }

  get terminalLabel() {
    return this.transaction?.terminal_name
  }

  get inboundContainerInfo() {
    return this.transaction?.inbound_appointment
  }

  get inboundAppointment() {
    return this.inboundContainerInfo?.appointment
  }

  get outboundContainerInfo() {
    return this.transaction?.outbound_appointment
  }

  get outboundAppointment() {
    return this.outboundContainerInfo?.appointment
  }

  get appointmentReferenceDescription() {
    return this.transaction?.primary_appointment.appointment
      .display_terminal_reference
  }

  get moveTypeDescription(): string {
    if (!this.transaction) return ''
    if (
      this.transaction.inbound_appointment &&
      this.transaction.outbound_appointment
    ) {
      return 'Dual'
    }
    return moveDescription(this.transaction.primary_appointment.appointment)
  }

  get statusDescription(): string {
    if (!this.transaction) return ''
    return this.transaction.primary_appointment.appointment.status
  }
}

export interface AppointmentFilters {
  terminals: TerminalName[]
  statuses: AppointmentStatus[]
  licensePlate: string | undefined
  moveType: MoveTypeFilter | undefined
  containers: Set<string>
  customerId: number | undefined
  selectedTags: string[]
}

function moveDescription(appointment: Appointment) {
  const directText =
    appointment.direction === TransactionDirection.Inbound ? 'In' : 'Out'
  const loadedText = appointment.loaded ? 'Load' : 'Empty'
  return `${loadedText} ${directText}`
}

export interface RescheduleInfo {
  row: AppointmentTableRow
  state: BookingRequestState
  originalAppointment: Appointment
}

export enum MoveTypeFilter {
  HasLoadOut = 'has_load_out',
  HasEmptyIn = 'has_empty_in',
  HasLoadIn = 'has_load_in',
  HasEmptyOut = 'has_empty_out',
  EmptyInOnly = 'empty_in_only',
  LoadOutOnly = 'load_out_only',
  LoadInOnly = 'load_in_only',
  EmptyOutOnly = 'empty_out_only',
  Dual = 'dual',
}
