<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useCurrentOrgStore } from '~/stores/currentOrg'
import { useUserStore } from '~/stores/user'
import Login from '~/components/navigation/Login.vue'
import type { GETToken } from '~/services/apiClient'

useHead({
  title: 'Dray Dog - Login',
})

const userStore = useUserStore()
const { selectOrgFromURL } = useCurrentOrgStore()
const router = useRouter()
const loginComplete = ref(false)

function goToContainersPage() {
  router.push({ name: 'containers' })
}

function goToEmptyReturnsPage() {
  router.push({ name: 'empty-returns' })
}

onMounted(() => {
  if (userStore.loggedIn) {
    if (userStore.isTerminalOrg) {
      goToEmptyReturnsPage()
    } else {
      goToContainersPage()
    }
  }
})
const LOADING_MESSAGES = [
  'You will be redirected shortly...',
  'One moment while we tidy up the dog house...',
  'Welcome BACK to the PACK! You may enter shortly...',
]
// Select a random message from the LOADING_MESSAGES array
const loadingMessage =
  LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)]

async function handleLogin(token: GETToken) {
  loginComplete.value = true
  await userStore.login(token)
  await selectOrgFromURL()
  if (!userStore.isTerminalOrg) {
    goToContainersPage()
  } else {
    goToEmptyReturnsPage()
  }
}
</script>

<template>
  <div
    v-if="loginComplete"
    class="my-auto top-20vh max-w-440px mx-auto text-center relative pt-20"
  >
    <div class="text-2xl font-semibold text-gray-600">
      <span>{{ loadingMessage }}</span>
    </div>
  </div>
  <Login v-else @login="handleLogin">
    <template #extra>
      <router-link
        :to="{ name: 'trigger_reset_password', query: { email: '' } }"
        class="underline cursor-pointer text-xs text-gray-500"
      >
        Forgot your password?
      </router-link>
    </template>
  </Login>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
name: login
</route>
