<script setup lang="ts" generic="T">
import { useIntersectionObserver } from '@vueuse/core'
import { onBeforeUnmount, ref, watch } from 'vue'

const props = defineProps<{
  item: T
  container: HTMLElement
}>()

const emit = defineEmits<{
  (event: 'visible', item: T): void
  (event: 'hidden', item: T, position: 'above' | 'below' | null): void
}>()

// Track whether this row is visible or not
const rowRef = ref<HTMLElement | null>(null)
const isVisible = ref(false)
const position = ref<'above' | 'below' | null>(null)

function setVisible(visible: boolean, positionToSet: 'above' | 'below' | null) {
  isVisible.value = visible
  position.value = positionToSet
}

useIntersectionObserver(
  rowRef,
  (entries) => {
    // Process entries in chronological order
    const sortedEntries = [...entries].sort((a, b) => a.time - b.time)
    for (const entry of sortedEntries) {
      let positionForThisEntry: 'above' | 'below' | null = null
      if (!entry.isIntersecting) {
        // Calculate if element is above or below viewport
        // boundingClientRect gives element position relative to viewport
        // rootBounds gives the root (container) bounds
        const elementTop = entry.boundingClientRect.top
        const containerTop = entry.rootBounds?.top || 0
        positionForThisEntry = elementTop < containerTop ? 'above' : 'below'
      }
      setVisible(entry.isIntersecting, positionForThisEntry)
    }
  },
  {
    rootMargin: '0px',
    threshold: 0.0, // Trigger as soon as a single pixel is visible
    root: props.container,
  }
)

// Emit events for changes in visibility
watch(isVisible, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return
  }
  if (newValue) {
    emit('visible', props.item)
  } else {
    emit('hidden', props.item, position.value)
  }
})

// React to changes in the object for the row
watch(
  () => props.item,
  (newItem, oldItem) => {
    if (newItem === oldItem) {
      return
    }
    emit('hidden', oldItem, null)
    if (isVisible.value) {
      emit('visible', newItem)
    }
  }
)

// Handle dismounting of the row
onBeforeUnmount(() => {
  emit('hidden', props.item, null)
})
</script>

<template>
  <div ref="rowRef">
    <slot />
  </div>
</template>
