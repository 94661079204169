<script setup lang="ts">
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import DispatchTable from './DispatchTable.vue'
import Filters from './Filters.vue'
import ShiftSelector from './shift_selector/ShiftSelector.vue'
import { useGroupedAppointmentsStore } from '~/stores/groupedAppointments'
import type { ShiftGroup } from '~/models/groupedAppointments'

const props = defineProps<{
  showOnlyLaTerminals?: boolean
  showCustomerColumn: boolean
}>()

const appointmentsStore = useGroupedAppointmentsStore()

const dispatchTable = ref<typeof DispatchTable | undefined>(undefined)
const selectedShift = ref<ShiftGroup | undefined>(undefined)
const firstVisibleShift = ref<ShiftGroup | undefined>(undefined)
const isProgrammaticScrolling = ref(false)
const containerDiv = ref<HTMLDivElement | undefined>(undefined)
const { filters, loading } = storeToRefs(appointmentsStore)

onMounted(() => {
  loadAppointments()
})

watch(selectedShift, () => {
  if (selectedShift.value) {
    isProgrammaticScrolling.value = true
    dispatchTable.value?.scrollToShift(selectedShift.value)
    setTimeout(() => {
      isProgrammaticScrolling.value = false
    }, 50)
  }
})

const HOURS_BACK_TO_LOAD_APPOINTMENTS = 12
const relevantShiftGroups = computed(() => {
  return appointmentsStore.appointmentShiftGroups.filter((shiftGroup) => {
    // We want to show the "current" shift and later, wich a little buffer during shift
    // transitions
    return shiftGroup.windowEnd > DateTime.now().minus({ hours: 2 })
  })
})

function loadAppointments() {
  return appointmentsStore
    .load({
      // Normally I would show fewer hours back, but then it might be weird if we don't
      // don't load all appointments in the current shift
      after: DateTime.now().minus({ hours: HOURS_BACK_TO_LOAD_APPOINTMENTS }),
      limitToRelevantAppointments: true,
    })
    .then(() => {
      // TODO: Consider having DispatchTable.vue be responsible for this. Like it
      // could emit a 'first-visible-shift-changed' event on data load even without
      // a user scroll.
      if (appointmentsStore.appointmentShiftGroups.length > 0) {
        firstVisibleShift.value = appointmentsStore.appointmentShiftGroups[0]
      }
    })
}

function onFirstVisibleShiftChanged(shift: ShiftGroup) {
  firstVisibleShift.value = shift
  if (
    shift.key !== selectedShift.value?.key &&
    !isProgrammaticScrolling.value
  ) {
    // When we scroll out of the current shift, it clears the selected shift
    selectedShift.value = undefined
  }
}
const gap = 10
</script>

<template>
  <div
    ref="containerDiv"
    v-loading="loading"
    class="w-full h-full flex flex-col"
  >
    <div>
      <Filters
        v-model="filters"
        :show-only-la-terminals="props.showOnlyLaTerminals"
        :show-customer-filter="props.showCustomerColumn"
        class="mb-2 mt-2"
      />
      <ShiftSelector
        v-model="selectedShift"
        :appointment-shift-groups="relevantShiftGroups"
        :first-visible-shift="firstVisibleShift"
        :style="{ marginBottom: `${gap}px` }"
      />
    </div>
    <div class="flex-grow">
      <el-auto-resizer>
        <template #default="{ height, width }">
          <DispatchTable
            ref="dispatchTable"
            :height="height - gap"
            :min-width="width - 10"
            :appointment-shift-groups="relevantShiftGroups"
            :selected-shift="selectedShift"
            @first-visible-shift-changed="onFirstVisibleShiftChanged"
          />
        </template>
      </el-auto-resizer>
    </div>
  </div>
</template>
