<script lang="ts" setup>
import type {
  CompcareCoreAPIConfig,
  ProfitToolsCoreAPIConfig,
} from '~/services/apiClient'

const { config, documentationLink, tmsName } = defineProps<{
  config: ProfitToolsCoreAPIConfig | CompcareCoreAPIConfig
  documentationLink: string | undefined
  tmsName: string
}>()

const editConfig = toReactive(config)
</script>

<template>
  <BaseIntegrationConnectionSettings
    :model-value="config"
    :documentation-link="documentationLink"
    :tms-name="tmsName"
  />
  <el-form-item label="Set empty appointment on leg start:">
    <el-checkbox v-model="editConfig.set_empty_appointment_time_on_leg_start" />
    <Help title="Set Empty Appointment on Leg Start" class="ml-2"
      >This tells us to set the empty appointment time on the hook-empty or
      mount-empty event instead of the drop-empty or dismount-empty event.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
  <el-form-item label="TMS Timezone:" label-width="auto">
    <el-input v-model="editConfig.timezone" :disabled="true" />
    <Help title="Timezone" class="ml-2"
      >This is the timezone your TMS is configured in.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
  <TmsTerminalCodeMapping
    :model-value="config.tms_terminal_mapping"
    tms-name="Profit Tools"
    :documentation-link="documentationLink"
  />
</template>
