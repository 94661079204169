<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useElementSize } from '@vueuse/core'
import { useTrucksStore } from '~/stores/trucks'
import 'element-plus/dist/index.css'
import {
  type Truck,
  type TruckWithStats,
  truckWithStatsFromTruck,
} from '~/models/trucks'
import TruckNameEditor from '~/components/trucks/TruckNameEditor.vue'
import UploadTrucksDialog from '~/components/dialogs/UploadTrucksDialog.vue'
import { useTruckStatsStore } from '~/stores/truckStats'
import { TRUCK_STATUS_DAYS_TO_LOAD } from '~/constants'

useHead({
  title: 'Dray Dog - Trucks',
})

const trucksStore = useTrucksStore()
const trucksStatsStore = useTruckStatsStore()

onMounted(() => {
  trucksStore.load(true)
  trucksStatsStore.load(null, true) // Load across all terminals
})

const hoveredTruckId = ref<number | null>(null)
const cardRef = ref<HTMLElement | null>(null)
const showArchived = ref(false)
const searchTruckText = ref('')
const showAddTruckDialog = ref(false)
const showUploadDialog = ref(false)
const { height: cardHeight } = useElementSize(cardRef)

const numArchived = ref(0)
const trucksWithStats = computed((): TruckWithStats[] => {
  // Filter
  let numArchivedTemporary = 0
  const filteredTrucks = trucksStore.trucks.filter((truck) => {
    if (truck.archived) numArchivedTemporary++
    if (!showArchived.value && truck.archived) return false
    if (searchTruckText.value) {
      const matched =
        truck.licensePlate.includes(searchTruckText.value) ||
        truck.name?.includes(searchTruckText.value)
      if (!matched) return false
    }
    return true
  })
  numArchived.value = numArchivedTemporary // It's a side effect, but whatever
  // Combine trucks with stats
  const stats = trucksStatsStore.statsByTerminal.get(null)
  const statsByTruckID = new Map<number, TruckWithStats>()
  stats?.forEach((stat) => {
    statsByTruckID.set(stat.truck.id, stat)
  })
  return filteredTrucks.map((truck) => {
    const truckStatus = truckWithStatsFromTruck(
      truck,
      statsByTruckID.get(truck.id)?.toGETTruckWithStats()
    )
    truckStatus.setStatsToZeroIfUndefined()
    return truckStatus
  })
})

function handleTruckUpdate(truck: Truck) {
  // TODO: Implement API call to update truck
  // For now, we'll just update the local store
  const index = trucksStore.trucks.findIndex((t) => t.id === truck.id)
  if (index !== -1) {
    trucksStore.trucks[index] = truck
  }
}

function handleAddTruck() {
  showAddTruckDialog.value = true
}

function handleCellHover(row: TruckWithStats) {
  hoveredTruckId.value = row.truck.id
}

function handleCellLeave() {
  hoveredTruckId.value = null
}
function makeSummaryRow() {
  return ['Total:', `${trucksStore.trucks.length} trucks`]
}
function missPercentageColorClass(missPercentage: number) {
  if (missPercentage < 10) return 'text-green-700'
  if (missPercentage < 30) return 'text-yellow-700'
  return 'text-red-700'
}
</script>

<template>
  <Card ref="cardRef" v-loading="trucksStore.loading" class="p-2 h-full">
    <div class="flex mb-3">
      <el-input
        v-model="searchTruckText"
        style="max-width: 300px"
        placeholder="Filter trucks by plate or name"
        clearable
      >
        <template #prepend>
          <el-icon>
            <i-mdi:magnify />
          </el-icon>
        </template>
      </el-input>
      <el-button type="primary" class="ml-2" @click="handleAddTruck">
        <i-mdi:plus-circle-outline class="mr-2" />
        Add Truck
      </el-button>
      <el-button
        v-if="numArchived > 0"
        class="ml-2"
        :type="showArchived ? 'warning' : 'default'"
        @click="showArchived = !showArchived"
      >
        <template v-if="showArchived">
          <i-mdi:eye-off class="mr-2" />
          Showing Archived trucks
        </template>
        <template v-else>
          <i-mdi:eye class="mr-2" />
          Show {{ numArchived }} Archived trucks
        </template>
      </el-button>
      <el-button class="ml-2" @click="showUploadDialog = true">
        <i-mdi:upload class="mr-2" />
        Add Trucks via Spreadsheet
      </el-button>
    </div>
    <el-table
      :data="trucksWithStats"
      :max-height="cardHeight"
      :show-summary="true"
      :summary-method="makeSummaryRow"
      :default-sort="{ prop: 'numCompleted', order: 'descending' }"
      class="w-full"
      @cell-mouse-enter="handleCellHover"
      @cell-mouse-leave="handleCellLeave"
    >
      <el-table-column
        prop="truck.licensePlate"
        label="Plate #"
        :width="120"
        sortable
      >
        <template #default="{ row }">
          <span>{{ row.truck.licensePlate }}</span>
          <span v-if="row.truck.archived" class="text-gray-400">
            <i-mdi:archive
              v-tooltip="`Truck is archived`"
              class="ml-2 align-text-bottom"
            />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Name" sortable :width="500" prop="truck.name">
        <template #default="{ row }">
          <TruckNameEditor
            :truck="row.truck"
            :hovered="hoveredTruckId === row.truck.id"
            class="w-full"
            @update="handleTruckUpdate"
          />
        </template>
      </el-table-column>

      <!-- Statistics section -->
      <el-table-column
        :label="`Appointment Statistics (last ${TRUCK_STATUS_DAYS_TO_LOAD} days)`"
      >
        <el-table-column label="Completed" prop="numCompleted" sortable />
        <el-table-column label="Missed" prop="numMissed" sortable>
          <template #default="{ row }">
            {{ row.numMissed }}
            <span
              v-if="row.missPercentage !== undefined"
              :class="missPercentageColorClass(row.missPercentage)"
            >
              ({{ row.missPercentage }}%)
            </span>
            <span v-else class="text-gray-400">N/A</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Scheduled (future)"
          prop="numScheduled"
          sortable
        />
      </el-table-column>
      <!-- Filler column, we'll put stats here later -->
      <!-- <el-table-column label=""></el-table-column> -->
    </el-table>
    <UploadTrucksDialog
      v-if="showUploadDialog"
      v-model="showUploadDialog"
      @close="showUploadDialog = false"
    />
    <AddTruckDialog
      v-model:visible="showAddTruckDialog"
      @truck-added="showAddTruckDialog = false"
    />
  </Card>
</template>

<route lang="yaml">
meta:
  auth: true
  layout: default
name: trucks
</route>
