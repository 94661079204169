<script lang="ts" setup>
import { ElMessageBox, ElNotification } from 'element-plus'
import { useContainerTagsStore } from '~/stores/containerTags'
import { ContainerTagsApi } from '~/services/apiClient'
import { useContainerFilters } from '~/compositions/containerFilters'

const props = withDefaults(
  defineProps<{
    /** The Tags Array  */
    modelValue: string[]
    placeholder?: string
  }>(),
  {
    placeholder: 'Select tags',
  }
)
const emit = defineEmits<{
  /** Emit Tags Array back to parent  */
  (e: 'update:modelValue', value: string | undefined): void
  (e: 'close', value: string | undefined): void
}>()
const selectedTags = useVModel(props, 'modelValue', emit)
const containerTagsStore = useContainerTagsStore()
const filters = useContainerFilters()
const { tagsFilter } = filters
type SelectRefElement = HTMLElement | null
const selectRef: Ref<SelectRefElement | null> = ref(null)
interface InputEvent extends Event {
  target: HTMLInputElement
}

const clearMultiSelectInput = async (event: InputEvent) => {
  event.target.value = '' // Clear the input

  // Attempt to close the dropdown by blurring the input field if possible
  if (selectRef.value) {
    // @ts-expect-error
    selectRef.value.query = '' // This helps to reset the internal state or query
    // @ts-expect-error
    selectRef.value.handleClose()
  }

  await nextTick() // give DOM time to update

  if (selectRef.value) {
    // @ts-expect-error
    const input = selectRef.value.$el.querySelector('input')

    // this will allow to trigger the dopdown with definedTags
    if (input) {
      input.blur()
      input.focus()
    }
  }
}

const onKeyupEnterAddTag = async (event: InputEvent) => {
  if (event) {
    const inputValue = event.target.value

    if (inputValue) {
      selectedTags.value.push(inputValue)
    }
    await clearMultiSelectInput(event)
  }
}

const onClickClose = (tagName: string) => {
  emit('close', tagName)
}

function deleteTag(tagToDelete: string) {
  // deselect the tag
  ElMessageBox.confirm(
    `Are you sure you want to delete the tag "${tagToDelete}"? 
    It will be removed from all containers and any capacity sets will be deleted.`,
    `Delete tag?`,
    {
      confirmButtonText: 'Yes, delete this tag',
      confirmButtonClass: 'el-button--danger',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  ).then(() => {
    const api = new ContainerTagsApi()
    api
      .deleteTagContainersTagsTagNameDelete(tagToDelete)
      .then(() => {
        // remove the preset from the options
        containerTagsStore.tags = containerTagsStore.tags.filter(
          (tag) => tag !== tagToDelete
        )
        // clear values
        emit('update:modelValue', undefined)
        // update the filters
        tagsFilter.value = tagsFilter.value.filter((tag) => tag !== tagToDelete)
        ElNotification({
          title: 'Tag deleted',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          message: 'Error deleting tag',
          type: 'error',
        })
      })
  })
}

function renameTag(tagToEdit: string) {
  // deselect the tag
  ElMessageBox.prompt('Tag name:', `Edit the name of the tag "${tagToEdit}"`, {
    confirmButtonText: 'Rename Tag',
    cancelButtonText: 'Cancel',
    inputValue: tagToEdit,
  }).then(({ value }) => {
    const api = new ContainerTagsApi()
    api
      .renameTagContainersTagsTagNamePut(tagToEdit, { name: value })
      .then(() => {
        // rename the tag in the options
        containerTagsStore.tags = containerTagsStore.tags.map((tag) =>
          tag === tagToEdit ? value : tag
        )
        // clear values
        emit('update:modelValue', undefined)
        // update the filters
        tagsFilter.value = tagsFilter.value.map((tag) =>
          tag === tagToEdit ? value : tag
        )
        ElNotification({
          title: 'Tag Renamed',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          message: 'Error renaming tag',
          type: 'error',
        })
      })
  })
}
</script>

<template>
  <el-select
    ref="selectRef"
    v-model="selectedTags"
    aria-label="Select tags"
    multiple
    :placeholder="props.placeholder"
    allow-create
    filterable
    size="large"
    @keyup.enter="onKeyupEnterAddTag"
    @remove-tag="onClickClose"
  >
    <el-option
      v-for="tag in containerTagsStore.tags"
      :key="tag"
      :label="tag"
      :value="tag"
      class="w-full flex justify-between"
    >
      <div>{{ tag }}</div>
      <div class="align-bottom" style="position: relative; right: -15px">
        <ElButton
          v-tooltip="`Rename tag ${tag}`"
          type="warning"
          size="small"
          circle
          plain
          @click="() => renameTag(tag)"
        >
          <i-mdi:pencil />
        </ElButton>
        <ElButton
          v-tooltip="`Delete tag ${tag}`"
          type="danger"
          size="small"
          class="ml-1"
          circle
          plain
          @click="() => deleteTag(tag)"
        >
          <i-mdi:trash-can />
        </ElButton>
      </div>
    </el-option>
  </el-select>
</template>
