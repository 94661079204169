import { DateTime } from 'luxon'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useUserStore } from './user'
import { TRUCK_STATUS_DAYS_TO_LOAD } from '~/constants'
import { TruckWithStats } from '~/models/trucks'
import type { TerminalName } from '~/services/apiClient'
import { TrucksApi } from '~/services/apiClient'

export const useTruckStatsStore = defineStore('truck-stats', () => {
  const userStore = useUserStore()
  const statsByTerminal = ref<Map<TerminalName | null, TruckWithStats[]>>(
    new Map()
  )
  const loadingPromiseByTerminal = ref<
    Map<TerminalName | null, Promise<TruckWithStats[]> | null>
  >(new Map())
  const loading = ref(false)

  function loadIfNecessary(
    terminal: TerminalName | null
  ): Promise<TruckWithStats[]> {
    // Currently loading case
    const promise = loadingPromiseByTerminal.value.get(terminal)
    if (promise) {
      return promise
    }
    // Already loaded case
    const existingStats = statsByTerminal.value.get(terminal)
    if (existingStats) {
      return Promise.resolve(existingStats)
    }
    // Start fresh load case
    return load(terminal)
  }

  function load(
    terminal: TerminalName | null,
    includeArchived: boolean = false
  ): Promise<TruckWithStats[]> {
    const api = new TrucksApi()
    const promise = api
      .getTrucksWithStatsTrucksWithStatsGet(
        DateTime.now().minus({ days: TRUCK_STATUS_DAYS_TO_LOAD }).toISODate(),
        terminal || undefined,
        includeArchived
      )
      .then((resp) => {
        const trucks = resp.data.map(
          (value) => new TruckWithStats(value.truck, userStore.demo_mode, value)
        )
        statsByTerminal.value.set(terminal, trucks)
        return trucks
      })
      .finally(() => {
        loadingPromiseByTerminal.value.set(terminal, null)
      })
    loadingPromiseByTerminal.value.set(terminal, promise)
    return promise
  }
  function getStatusForTerminal(
    terminal: TerminalName | null
  ): TruckWithStats[] {
    return statsByTerminal.value.get(terminal) || []
  }

  return {
    loading,
    statsByTerminal,
    load,
    loadIfNecessary,
    getStatusForTerminal,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTruckStatsStore, import.meta.hot))
}
