import { AppointmentStatus } from '~/services/apiClient'

export const statusText = (status: AppointmentStatus): string => {
  switch (status) {
    case AppointmentStatus.Scheduled:
      return 'Scheduled'
    case AppointmentStatus.InProgress:
      return 'In Progress'
    case AppointmentStatus.Completed:
      return 'Completed'
    case AppointmentStatus.Cancelled:
      return 'Cancelled'
    case AppointmentStatus.Missed:
      return 'Missed'
    case AppointmentStatus.SchedulingError:
      return 'Error'
    case AppointmentStatus.Aborted:
      return 'Aborted'
    default:
      return 'Unknown'
  }
}

export const getStatusType = (
  status: AppointmentStatus
): 'primary' | 'success' | 'warning' | 'info' | 'danger' => {
  switch (status) {
    case AppointmentStatus.Scheduled:
      return 'primary'
    case AppointmentStatus.InProgress:
      return 'warning'
    case AppointmentStatus.Completed:
      return 'success'
    case AppointmentStatus.Cancelled:
      return 'info'
    case AppointmentStatus.Missed:
      return 'danger'
    case AppointmentStatus.SchedulingError:
      return 'warning'
    case AppointmentStatus.Aborted:
      return 'danger'
    default:
      return 'info'
  }
}
